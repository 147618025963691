import React, { useEffect, useState } from "react";
import '../../styles/ourgallery.css';
import Rectangle_15 from '../../images/Rectangle_15.png'
import Rectangle_16 from '../../images/Rectangle_16.png'
import Rectangle_17 from '../../images/Rectangle_17.png'
import Rectangle_18 from '../../images/Rectangle_18.png'
import Rectangle_19 from '../../images/Rectangle_19.png'
import Rectangle_20 from '../../images/Rectangle_20.png'
import Rectangle_21 from '../../images/Rectangle_21.png'
import Rectangle_22 from '../../images/Rectangle_22.png'
import Rectangle_23 from '../../images/Rectangle_23.png'
import axios from "axios";

const widthOutPut = window.screen.width;
const headerMainFontSize = (5 / 100) * widthOutPut;
const slidePerRow = 4;

const OurGallery = () => {


    const [data, setData] = useState([]);
    const [next, setNext] = useState(slidePerRow)
    const [activeLoad, setActiveLoad] = useState(false)


    const fetchGalleryData = async () => {
        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/gallery/all`;

            const res = await axios.get(url)
            const { data } = res;
            setData(data)
        } catch (error) {

        }
    }

    const handleMoreSlide = () => {
        setNext(next + slidePerRow)
        if (next > data.length) {
            setActiveLoad(true)
        }
    }
    const handleLessSlide = () => {
        setNext(slidePerRow)

    }


    useEffect(() => {
        fetchGalleryData()
    }, []);



    return (
        <>
            <div className="mainDiv_gallery">
                <div className="headerDiv_gallery">
                    <h1 style={{
                        fontSize: `${headerMainFontSize}px`
                    }}>OUR GALLERY</h1>
                </div>
                <div className="ourGalleryMainDiv">
                    {
                        data?.slice(0, next)?.map((item, i) => {
                            return (<>
                                <div className="imageFirstDiv_gallery">
                                    <div className="imageInnerSecond_gallery">
                                        <img src={item.image} />
                                    </div>
                                </div>

                            </>)
                        })
                    }
                </div>
                <div className="Btn_gallery">
                    {next < data?.length ? (
                        <button
                            onClick={handleMoreSlide}
                        >
                            {activeLoad ? "Load Less" : "Load More"}
                        </button>
                    ) : <button
                        onClick={handleLessSlide}
                    >
                        {activeLoad ? "Load More" : "Load Less"}
                    </button>}
                </div>
            </div>
        </>
    )
}
export default OurGallery;