import React, { useState } from "react";
import axios from 'axios'
import '../styles/login.css'
import MobileWithLogo from '../images/phonewithLogo.png';
import UserIcon from "../svg/userIcon.svg";
import EyeOff from "../svg/eye-off.svg"
import LoginLeftShade from "../svg/loginLeftShade.svg"
import { pagePaths } from "../utils/constants";
import CompanyLogoIcon from "../svg/logo.svg"

export const Login = () => {
    const [logindata, setLoginData] = useState({
        email: "",
        password: "",
    })




    const handleSubmit = (e) => {
        e.preventDefault();
        const userData = {
            email: logindata.email,
            password: logindata.password,
        };
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, userData).then((response) => {
        }
        );
    };





    const handleChange = (e) => {
        const value = e.target.value;
        setLoginData({
            ...logindata,
            [e.target.name]: value
        });
    };



    return (
        <>
            <div className="mainDiv_login">
                <div className="logoMobile">
                    <img src={MobileWithLogo} />
                </div>
                <img src={LoginLeftShade} className="LoginLeftShade" />
                <div className="loginLowerDiv">
                    <div className="userLoginDiv">
                        <a href={pagePaths.root}><img src={CompanyLogoIcon} /></a>
                    </div>
                    <div className="headerLoginDiv">
                        <h1>WELCOME</h1>
                    </div>
                    <div className="userAndPassword">
                        <div className="loginUser" >
                            <img src={UserIcon} />
                            <input
                                type="email"
                                name="email"
                                value={logindata.email}
                                placeholder="Email Address"
                                className="input"
                                onChange={handleChange} />
                        </div>
                        <div className="loginPassword">
                            <img src={EyeOff} />
                            <input
                                type="password"
                                name="password"
                                value={logindata.password}
                                placeholder="Password"
                                className="input"
                                onChange={handleChange} />
                        </div>
                        <div className="forgetPassword">
                            <a href={pagePaths.forgetPassword}>Forget Password?</a>
                        </div>
                    </div>
                    <div className="createLogin">
                        <h4>New to Masoom Trust? &nbsp;</h4>
                        <a href={pagePaths.signup}>Create Account</a>
                    </div>
                    <div className="loginBtn">
                        <button type="submit" onClick={handleSubmit}>Login</button>
                    </div>
                </div>
            </div>
        </>
    )
}