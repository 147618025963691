import React from "react";
import AliceCarousel from 'react-alice-carousel';
import PrevBtn from '../../images/prev.png'
import NextBtn from '../../images/nxt.png'
import { ProgressBar, ProgressBar1 } from "../../container/progressbar";
import { useSearchParams } from "react-router-dom";


const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};


const AliceCarouselComp = (props) => {
    const {data,slide} = props
    const [searchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const id = params.id;
    return (<>
        <div className="Categories_Div_lower">
            <AliceCarousel infinite autoPlay={true} autoPlayInterval={7000} responsive={responsive} disableDotsControls renderPrevButton={() => {
                return <img src={PrevBtn} className="Categories_prevSvg" />
            }} renderNextButton={() => {
                return <img src={NextBtn} className="Categories_nxtSvg" />
            }} >

                {
                    data?.map((item, i) => {

                        const progressBarPercent = (item.raisedAmount / item.goalAmount) * 100;
                        const progressBarMaxWidth = () => {
                            if (progressBarPercent > 100) {
                                return 100
                            } else {
                                return progressBarPercent
                            }
                        }
                        console.log("checking ids", item.id, id)
                        return (<>
                            <div className="Categories_sliderImgMainDiv" >
                                <div className="Categories_sliderImgMain">
                                    <a key={i} href={`aboutuspage?id=${item.id}`}><img src={item.image1} className="Categories_sliderimg" /></a>
                                    <div className="Categories_belowSliderImg">
                                        <div className="catgriesSliderDataleft">
                                            <h4 className="catgriesSliderDataleftUpper">{item.title}</h4>
                                            <button>
                                                Donate Now
                                            </button>
                                        </div>
                                        <div className="catgriesSliderDataRight" >
                                            <h2 className="categriesSliderRightPanelupper">₹{item.raisedAmount}<br /> </h2>
                                            <h3 className="categriesSliderRightPanellower">raised out of ₹{item.goalAmount}</h3>
                                        </div>
                                    </div>
                                </div>
                                <ProgressBar1 progressBarPercent={progressBarMaxWidth()} />
                            </div>
                        </>)
                    })
                }
            </AliceCarousel>
        </div>
    </>)
}
export default AliceCarouselComp;