import React, { useEffect, useState } from "react";
import '../../styles/risefund.css'
import IndiamapLeft1SVG from '../../svg/indiaMap_left1.svg';
import IndiamapLeft2SVG from '../../svg/indiaMap_Left2.svg';
import IndiamapRight1SVG from '../../svg/indiaMap_right1.svg';
import IndiamapRight2SVG from '../../svg/indiaMap_Right2.svg';
import Medical from '../../svg/medical.svg';
import Rectangle_24 from '../../images/Rectangle_24.png'
import Rectangle_25 from '../../images/Rectangle_25.png'
import Rectangle_26 from '../../images/Rectangle_26.png'
import Rectangle_27 from '../../images/Rectangle_222.jpg'
import axios from "axios";

const widthOutPut = window.screen.width;
const headerMainFontSize = (5 / 100) * widthOutPut;

const RiseFund = () => {
    const [dataCategories, setDataCategories] = useState([]);

    const fetchData_Categories = async () => {
        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/category/all`;

            const res = await axios.get(url)
            const { data } = res;
            setDataCategories(data)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData_Categories()
    }, [])
    return (
        <>
            <div className="mainDiv_riseFund">
                <img src={IndiamapLeft1SVG} className="india_map_left1_riseFund" />
                <img src={IndiamapLeft2SVG} className="india_map_left2_riseFund" />
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <img src={IndiamapRight1SVG} className="india_map_right1_riseFund" />
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <img src={IndiamapRight2SVG} className="india_map_right2_riseFund" />
                </div>
                <div>
                    <div className="header_riseFund">
                        <h1 style={{
                            fontSize: `${headerMainFontSize}px`
                        }}>Causes you can raise funds for</h1>
                    </div>
                    <div className="containPara_riseFund">
                        <h2>
                            Be it for a personal need, social cause or a creative idea - you can count on us for the project that you want to raise funds for.
                        </h2>
                    </div>
                </div>
                {/* <div className="IndiaMapmainDiv_riseFund">
                    <div className="IndiaMap_riseFund">
                        <img src={IndiaMap} />
                    </div>
                </div> */}
                <div className="cardmainDiv_riseFund">
                    <div className="cardInnerDiv_riseFund">
                        {
                            dataCategories?.map((item) => {
                                return (<>
                                    <div className="card1_riseFund">
                                        <a
                                            href={`browserfundrasierdropdown?id=${item.id}`}
                                            style={{ color: "black", textDecoration: "none", zIndex: 1 }}
                                        >
                                            <img src={item.icon} />
                                            <h3 style={{ cursor: "pointer" }}>{item.name}</h3>
                                        </a>

                                    </div>
                                </>)
                            })
                        }

                    </div>
                </div>
                <div className="images_riseFund">
                    <div style={{ width: "90%", zIndex: 1 }}>
                        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                            <img style={{ width: "49%" }} src={Rectangle_24} />
                            <img style={{ width: "49%" }} src={Rectangle_25} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                            <img style={{ width: "49%" }} src={Rectangle_26} />
                            <img style={{ width: "49%" }} src={Rectangle_27} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RiseFund;