import React from "react";
import "../styles/activitiesSubEduction.css"
import Layout from "../component/Layout";
import EductionIcon from "../svg/educationHat.svg";
import Img1 from "../svg/activites1img.svg"
import Img2 from "../svg/activities2img.svg"
import LowerImg from "../svg/activitiesLowerImg.svg"


const widthOutPut = window.screen.width;
const heightOutput = window.screen.height;
const headingWidth = (33 / 100) * widthOutPut;
const headingMobileWidth = (60 / 100) * widthOutPut;
const mobileHeadingWidth = (widthOutPut > 720) ? headingWidth : headingMobileWidth;

const headingWebFontSize = (1 / 100) * widthOutPut;
const headingMobileFontSize = (2.2 / 100) * widthOutPut;
const mobileHeadingFontSize = (widthOutPut > 720) ? headingWebFontSize : headingMobileFontSize;

const widthUnderLine = (15 / 100) * headingWidth;

const iconWidth = (3.19 / 100) * widthOutPut;
const iconMobileWidth = (8.19 / 100) * widthOutPut;
const iconHeight = (4.23 / 100) * widthOutPut;
const iconMobileHeight = (8.23 / 100) * widthOutPut;

const mobileIconWidth = (widthOutPut > 720) ? iconWidth : iconMobileWidth
const mobileIconHeight = (widthOutPut > 720) ? iconHeight : iconMobileHeight

const contentFontSize = (1.5 / 100) * widthOutPut;
const contentMobileFontSize = (3.2 / 100) * widthOutPut;
const mobileContentFontSize = (widthOutPut > 720) ? contentFontSize : contentMobileFontSize;

const contentPFontSize = (1 / 100) * widthOutPut;
const contentPMobileFontSize = (3.2 / 100) * widthOutPut;
const mobilePContentFontSize = (widthOutPut > 720) ? contentPFontSize : contentPMobileFontSize;

const contentimg = (33 / 100) * widthOutPut;
const contentMobileimg = (70 / 100) * widthOutPut;
const mobileContentmg = (widthOutPut > 720) ? contentimg : contentMobileimg;

const donationContentFontSize = (2.3 / 100) * widthOutPut;
const donationContentMobileFontSize = (2.3 / 100) * widthOutPut;
const mobiledonationContentFontSize = (widthOutPut > 720) ? donationContentFontSize : donationContentMobileFontSize;

const donationContentHeight = (50 / 100) * heightOutput;
const donationContentMobileHeight = (25 / 100) * heightOutput;
const mobileDonationContentHeight = (widthOutPut > 720) ? donationContentHeight : donationContentMobileHeight;

const donateBtnFontSize = (1.1 / 100) * widthOutPut;
const donateBtnMobileFontSize = (2.7 / 100) * widthOutPut;
const mobileDonateBtnFontSize = (widthOutPut > 720) ? donateBtnFontSize : donateBtnMobileFontSize;

const doanteBtnHeight = (15 / 100) * donationContentHeight
const doanteBtnWidth = (45 / 100) * donationContentHeight



export const ActivitiesSubEduction = () => {
    return (<>
        <Layout>
            <div className="mainDiv_ActivitiesEduction">
                <div className="inner1ManiDiv_ActivitiesEduction">
                    <div style={{
                        width: `${mobileHeadingWidth}px`,
                        fontSize: `${mobileHeadingFontSize}px`
                    }} className="InnerHeaderDiv_ActivitiesEduction">
                        <img style={{
                            width: `${mobileIconWidth}px`,
                            height: `${mobileIconHeight}px`
                        }} src={EductionIcon} />
                        <h1>
                            Raise Funds For The Future  Of Education
                        </h1>
                        <div style={{
                            width: `${widthUnderLine}px`
                        }} className="underLine_ActivitiesEduction"></div>
                    </div>
                </div>
                <div className="inner2MainDiv_ActivitiesEduction">
                    <div className="innerInnerUpperDiv_ActivitiesEduction">
                        <div className="innerUpperContentDiv_ActivitiesEduction">
                            <h3 style={{
                                fontSize: `${mobileContentFontSize}px`,
                            }}>
                                Our Aim towards education
                            </h3>
                            <p style={{
                                fontSize: `${mobilePContentFontSize}px`
                            }}>
                                We care about the future citizens of our society as providing education creates a lasting impact in the development of the society. Our projects include providing improved schooling infrastructures, skill enhancement training, tools and teaching equipment, book give away, computer lab formation, library development programs, providing support and exposure for the children.
                            </p>
                        </div>
                        <div className="innerDownImg_ActivitiesEduction">
                            <img style={{
                                width: `${mobileContentmg}px`
                            }} src={Img1} />
                        </div>
                    </div>
                    <div className="innerInnerUpperDiv_ActivitiesEduction innerInner2UpperDiv_ActivitiesEduction">
                        <div className="innerDownImg_ActivitiesEduction">
                            <img style={{
                                width: `${mobileContentmg}px`
                            }} src={Img2} />
                        </div>
                        <div className="innerUpperContentDiv_ActivitiesEduction  innerDownContentDiv_ActivitiesEduction">
                            <h3 style={{
                                fontSize: `${mobileContentFontSize}px`
                            }}>
                                raise fund for bright future
                            </h3>
                            <p style={{
                                fontSize: `${mobilePContentFontSize}px`
                            }}>
                                We are reaching out to our dedicated parents, alumni, and supporters to ask for your help in achieving our fundraising goals. Your generosity can make a significant impact on the lives of our students, helping us to provide them with the best possible education and prepare them for a bright future.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="lowwerMainDiv_ActivitiesEduction">
                    <div style={{
                        height: `${mobileDonationContentHeight}px`
                    }} className="LowerinnerMainDiv_ActivitiesEduction">
                        <div className="LowerinnerDiv_ActivitiesEduction">
                            <h1 style={{
                                fontSize: `${mobiledonationContentFontSize}px`
                            }}>
                                giving is not just aboutmaking a donation, it is all about making a difference
                            </h1>
                            <div style={{
                                width: `${widthUnderLine}px`
                            }} className="underLine"></div>
                        </div>
                        <div className="donateBtn_ActivitiesEduction">
                            <button style={{
                                fontSize: `${mobileDonateBtnFontSize}px`,
                                height:`${doanteBtnHeight}px`,
                                width:`${doanteBtnWidth}px`
                            }}>
                                Donate Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}