import React, { useCallback, useEffect, useState } from "react";
import '../../styles/homepage.css'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Img1_rect from '../../svg/img1_rect.svg'
import Img2_rect from '../../svg/img2_rect.svg'
import Img3_rect from '../../svg/img3_rect.svg'
import PrevBtn from '../../images/prev.png'
import NextBtn from '../../images/nxt.png'
import TrendImg1 from '../../images/Rectangle5.png'
import TrendImg2 from '../../images/Rectangle6.png'
import TrendImg3 from '../../images/Rectangle7.png';
import Traingle from '../../svg/triangle.svg'
import Traingle2 from '../../svg/traingle2.svg'
import Star from '../../svg/star.svg'
import CurveLine1 from '../../svg/line1.svg'
import CurveLine2 from '../../svg/line2.svg'
import zIndex from "@mui/material/styles/zIndex";
import { pagePaths } from "../../utils/constants";
import MainImage from "../../images/mainImage.png"
import axios from "axios";
import { ProgressBar, ProgressBar1 } from "../../container/progressbar";


const widthOutPut = window.screen.width;
const HeadingFontSize = (4 / 100) * widthOutPut;
const HeadingMobileFontSize = (6 / 100) * widthOutPut;
const widthHeadingWeb = (30 / 100) * widthOutPut;

const headingChangeFontSize = (widthOutPut > 720) ? HeadingFontSize : HeadingMobileFontSize;
const widthHeadingChange = (widthOutPut > 720) ? widthHeadingWeb : widthOutPut;

const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
};

const HomePage = () => {

    const [data, setData] = useState([]);

    const fetchData = useCallback(async () => {

        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/campaign/trending`;

            const res = await axios.get(url)
            const { data } = res;
            setData(data)
        } catch (error) {

        }
    }, [])


    useEffect(() => {
        fetchData()
    }, [fetchData]);




    return (
        <>
            <div>
                <div>
                    <div>
                        <div className="upper1_div">
                            <div className="upper1_div_1">
                                <h1 style={{
                                    fontSize: `${headingChangeFontSize}px`,
                                    width: `${widthHeadingChange}px`
                                }}>Need Funds to Pay
                                    For a Medical
                                    Emergency or
                                    Social Cause?</h1>
                            </div>
                            <div className="upper1_div_2">
                                <img src={MainImage} />
                            </div>
                        </div>
                        {/* <img src={Traingle} className="traingle" /> */}
                        <div className="lowerDivMain">
                            <div className="lowerDiv">
                                <div className="lowerDiv1">
                                    <div className="lowerDiv1_1">
                                        <div>
                                            <h1>0%</h1>
                                        </div>
                                        <div>
                                            <h2>PLATFORM FEE</h2>
                                        </div>
                                    </div>
                                    <div className="lowerDiv1_1">
                                        <div>
                                            <h1>72 LAKH+</h1>
                                        </div>
                                        <div>
                                            <h2>DONORS</h2>
                                        </div>
                                    </div>
                                    <div className="lowerDiv1_1">
                                        <div>
                                            <h1>3.2 LAKH+</h1>
                                        </div>
                                        <div>
                                            <h2>FUNDRAISERS</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="lowerDivtrust">
                                    <h1>Masoom Trust 0% Platform fees ensures<br></br>
                                        maximum funds for you</h1>
                                </div>
                                <img src={Traingle2} className="traingle2" />
                                <div className="lowerDivBtnMain">
                                    <a className="lowerDivBtn" href={pagePaths.signup}>Start a Fundraisers For Free</a>
                                    <div >
                                    </div>
                                    <img src={Star} className="star" />
                                </div>
                            </div>
                        </div>
                        {/*   */}
                    </div>
                    <div className="tranding_Div">
                        <div className="tranding_Div_upper">
                            <h1>Trending Fundraisers</h1>
                            <h3>View the fundraisers that are most active right now</h3>
                        </div>
                        <div className="tranding_Div_lowerMain">
                            <div className="tranding_Div_lower">
                                <AliceCarousel infinite autoPlay={true} autoPlayInterval={7000} responsive={responsive} disableDotsControls renderPrevButton={() => {
                                    return <img src={PrevBtn} className="prevSvg" />
                                }} renderNextButton={() => {
                                    return <img src={NextBtn} className="nxtSvg" />
                                }} >

                                    {
                                        data?.map((item, i) => {
                                            const progressBarPercent = (item.raisedAmount / item.goalAmount) * 100;
                                            const progressBarMaxWidth = () => {
                                                if (progressBarPercent > 100) {
                                                    return 100
                                                } else {
                                                    return progressBarPercent
                                                }
                                            }
                                            return (<>
                                                <div className="Categories_sliderImgMainDiv" >
                                                    <div className="Categories_sliderImgMain">
                                                        <a key={i} href={`aboutuspage?id=${item.id}`}><img src={item.image1} className="Categories_sliderimg" /></a>
                                                        <div className="Categories_belowSliderImg" style={{ display: "flex", padding: "0" }}>
                                                            <div className="catgriesSliderDataleft">
                                                                <h4 className="catgriesSliderDataleftUpper">{item.title}</h4>
                                                                {/* <h5 style={{ color: "white" }}>{item.description}</h5> */}
                                                                <button>
                                                                    Donate Now
                                                                </button>
                                                            </div>
                                                            <div className="catgriesSliderDataRight">
                                                                <h2 className="categriesSliderRightPanelupper">₹{item.raisedAmount}<br /> </h2>
                                                                <h3 className="categriesSliderRightPanellower">raised out of ₹{item.goalAmount}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ProgressBar1 progressBarPercent={progressBarMaxWidth()} />
                                                </div>
                                            </>)
                                        })
                                    }


                                </AliceCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomePage;