import React from "react";
import "../styles/withdrawfund.css"
import Layout from "../component/Layout";
import DocumentIcon from "../svg/document.svg"
import FolderIcon from "../svg/folder.svg"



const dummyArray = [
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "Is it possible to refunds the funds back to the donors after the funds are withdrawn?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
    {
        icon: DocumentIcon,
        content: "When will my bank account be reviewed?"
    },
]

export const WithDrawFund = () => {
    return (<>
        <Layout >
            <div className="mainDiv_withdraw" >
                <div className="innerMain_withdraw">
                    <div style={{ width: "60%" }}>
                        <div className="innerMainLeftDiv">
                            {
                                dummyArray?.map((item, id) => {
                                    return (<>
                                        <div className="innerInnerMain_withdraw">
                                            <div key={id} className="icon_withdraw">
                                                <img src={item.icon} />
                                            </div>
                                            <div key={id} className="heading_withdraw">
                                                <h4>{item.content}</h4>
                                            </div>
                                        </div>
                                    </>)
                                })
                            }

                        </div>
                    </div>
                    <div className="InnerMainRightDiv" style={{ width: "40%" }}>
                        <div className="InnerMainRightDivInner">
                            <div className="InnerMainRightDivInnerImgDiv">
                                <img className="InnerMainRightDivInnerImg" src={FolderIcon} />
                            </div>
                            <div className="InnerMainRightDivInnerContentDiv">
                                <h2>KB Selection</h2>
                            </div>
                        </div>
                        <div className="InnermainDownDiv_withdraw">
                            <h2>Funds Withdrawal</h2>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    </>)
}