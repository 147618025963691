import React from "react";
import Layout from "../component/Layout";
import "../styles/whatisCrowdingFund.css"
import SimpleStep from "./simpleStep";
import { FcMoneyTransfer } from "react-icons/fc"

export const WhatIsCrowdFundig = () => {
    return (<>
        <Layout>
            <div>
                <div className="headerMainDiv_whatiscrowd">
                    <div className="heading_whatiscrowd">
                        <h1>What is Crowdfunding?</h1>
                        <p>In its simplest form, Crowdfunding is a practice of giving monetary funds to overcome specific social, cultural, or economic hurdles individuals face in their daily lives.</p>
                    </div>
                    <div className="content_whatiscrowd">
                        <h2>
                            So how does Crowdfunding work On Masoom?
                        </h2>
                        <p>Let us assume an individual, unfortunately, meets with an accident on the road.
                            His medical expenses and hospital bills start piling up.
                            Now he needs ₹5 Lakh to pay his expensive medical bills.
                            Fortunately, his best friend signed up on Masoom’s crowdfunding platform, completed the process of submitting valid documents needed for verification. In a few minutes, he created a crowdfunding campaign to raise funds for his friend’s medical expenses.
                            Now, this campaign can be shared with all his near and dear ones through WhatsApp, Instagram, Twitter, Facebook and E-mail. In a matter of few minutes, funds start coming in to support the financial needs of the injured friend.</p>
                    </div>
                </div>
                <div>
                    <SimpleStep />
                </div>
                <div className="details_whatiscrowdfund">
                    <div className="detailsInner_whatiscrowdfund">
                        <div style={{ textAlign: "center" }}>
                            <h1>We provide everything you need</h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", padding: "2rem" }}>
                            <div className="details1Row">
                                <div style={{ marginTop: "2%" }}>
                                    <div className="detailsHeader" >
                                        <FcMoneyTransfer size={40} />
                                        <h2><span>P</span>ayout</h2>
                                    </div>
                                    <div className="detailParagraph">
                                        <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                        </p>
                                    </div>
                                </div>
                                <div style={{ marginTop: "2%" }}>
                                    <div className="detailsHeader" >
                                        <FcMoneyTransfer size={40} />
                                        <h2><span>P</span>ayout</h2>
                                    </div>
                                    <div className="detailParagraph">
                                        <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                        </p>
                                    </div>
                                </div>
                                <div style={{ marginTop: "2%" }}>
                                    <div className="detailsHeader" >
                                        <FcMoneyTransfer size={40} />
                                        <h2><span>P</span>ayout</h2>
                                    </div>
                                    <div className="detailParagraph">
                                        <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                        </p>
                                    </div>
                                </div>
                                <div style={{ marginTop: "2%" }}>
                                    <div className="detailsHeader" >
                                        <FcMoneyTransfer size={40} />
                                        <h2><span>P</span>ayout</h2>
                                    </div>
                                    <div className="detailParagraph">
                                        <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="details1Row">
                                <div className="details1Row">
                                    <div style={{ marginTop: "2%" }}>
                                        <div className="detailsHeader" >
                                            <FcMoneyTransfer size={40} />
                                            <h2><span>P</span>ayout</h2>
                                        </div>
                                        <div className="detailParagraph">
                                            <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "2%" }}>
                                        <div className="detailsHeader" >
                                            <FcMoneyTransfer size={40} />
                                            <h2><span>P</span>ayout</h2>
                                        </div>
                                        <div className="detailParagraph">
                                            <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "2%" }}>
                                        <div className="detailsHeader" >
                                            <FcMoneyTransfer size={40} />
                                            <h2><span>P</span>ayout</h2>
                                        </div>
                                        <div className="detailParagraph">
                                            <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "2%" }}>
                                        <div className="detailsHeader" >
                                            <FcMoneyTransfer size={40} />
                                            <h2><span>P</span>ayout</h2>
                                        </div>
                                        <div className="detailParagraph">
                                            <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details1Row">
                                <div className="details1Row">
                                    <div style={{ marginTop: "2%" }}>
                                        <div className="detailsHeader" >
                                            <FcMoneyTransfer size={40} />
                                            <h2><span>P</span>ayout</h2>
                                        </div>
                                        <div className="detailParagraph">
                                            <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "2%" }}>
                                        <div className="detailsHeader" >
                                            <FcMoneyTransfer size={40} />
                                            <h2><span>P</span>ayout</h2>
                                        </div>
                                        <div className="detailParagraph">
                                            <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "2%" }}>
                                        <div className="detailsHeader" >
                                            <FcMoneyTransfer size={40} />
                                            <h2><span>P</span>ayout</h2>
                                        </div>
                                        <div className="detailParagraph">
                                            <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "2%" }}>
                                        <div className="detailsHeader" >
                                            <FcMoneyTransfer size={40} />
                                            <h2><span>P</span>ayout</h2>
                                        </div>
                                        <div className="detailParagraph" style={{ position: "relative", left: "20%" }}>
                                            <p>This is your money, you can withdraw it at any point during the course of your fundraiser
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}