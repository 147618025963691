import React, { useEffect, useState } from "react";
import '../styles/successStory.css';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import PrevBtn from '../images/prev.png'
import NextBtn from '../images/nxt.png'
import { pagePaths } from "../utils/constants";
import axios from "axios";
import Layout from "../component/Layout";

const responsive = {
    0: { items: 1 },
};

export const SuccessStoriesFooter = () => {
    ///site/success/all

    const [data, setData] = useState([]);

    const fetchData = async () => {
        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/success/all`;

            const res = await axios.get(url)
            const { data } = res;
            setData(data)
        } catch (error) {

        }
    }

    console.log("checking success story fetch", data)
    useEffect(() => {
        fetchData()
    }, []);
    return (
        <>
            <Layout>
                <div className="mainDiv_Success">
                    <div className="header_Success">
                        <h1>Success Stories</h1>
                    </div>
                    <div className="cardContain_Success">
                        <AliceCarousel responsive={responsive} disableDotsControls renderPrevButton={() => {
                            return <img src={PrevBtn} className="prevSvg_Success" />
                        }} renderNextButton={() => {
                            return <img src={NextBtn} className="NxtSvg_Success" />
                        }} >
                            {
                                data?.map((item, i) => {
                                    return (<>
                                        <div style={{ width: "100%" }}>
                                            <div className="sliderImgMainDiv_Success">
                                                <div className="sliderImgMain_Success">
                                                    <a key={i} ><img src={item.image} className="Categories_sliderimg" /></a>
                                                    <div className="belowSliderImg_Success">
                                                        <h4 style={{ color: "white" }}>{item.content}</h4>
                                                        <button className="readMore_Success">Read More</button>
                                                        <div className="belowSliderImg_SS_lower_Success">
                                                            <h3>Subscribe more to read such stories</h3>
                                                            <button>Subscribe</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                })
                            }
                        </AliceCarousel>
                    </div>
                    <div className="stepLower_Div_Success">
                        <div className="StepLower_DivBtn_Success">
                            <a href={pagePaths.signup} className="start_Btn_Success">START A FUNDRAISERS FOR FREE</a>
                            <button className="talk_Btn_Success">
                                TALK TO US
                            </button>
                        </div>
                        <div className="chat_Btn_Success">
                            <h4>Have any questions for us? Chat with our team on Facebook or WhatsApp now.</h4>
                            <button className="chatUS_Btn_Success">CHAT WITH US</button>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}