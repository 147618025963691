import React from "react";
import "../styles/otherActivities.css";
import Layout from "../component/Layout";
import OtherActivitiesImg1 from "../images/OtherActivitiesImg1.png"

const widthOutput = window.screen.width;
const heightOutput = window.screen.height
const headerFontSize = (4 / 100) * widthOutput;

export const OtherActivities = () => {
    return (<>
        <Layout>
            <div className="mainPAge_OtherActivities">
                <div className="headerMAinDIv_OtherActivities">
                    <div className="headingDiv_OtherActivities">
                        <div style={{
                            display: "flex"
                        }}>
                            <h1 style={{
                                fontSize: `${headerFontSize}px`
                            }}>Other&nbsp; </h1>
                            <div>
                                <h1 style={{
                                    fontSize: `${headerFontSize}px`
                                }}>
                                    Activities
                                </h1>
                                <div style={{
                                    // width: `${widthUnderLine}px`
                                }} className="underLine_OtherActivities"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="MAinDiv_OtherActivities">
                    <div className="MainDiv_OtherActivities">
                        <div className="InnerMAinDiv_OtherActivities">
                            <div className="Header_OtherActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.5}px`
                                }}>Flood Relief CSR Programs</h2>
                            </div>
                            <div className="underLineHeadingMainDiv_MasoomTruestActivities">
                                <div className="underLineHeading_MasoomTruestActivities"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ContentMainDiv_OtherActivities">
                    <div className="ContentInnerMainDiv_OtherActivities">
                        <div className="contentImgMainDiv_OtherActivities">
                            <img src={OtherActivitiesImg1} />
                        </div>
                        <div className="contentDiv_OtherActivities">
                            <h2 style={{
                                fontSize: `${headerFontSize * 0.35}px`
                            }}>Flood Relief for Andhra Pradesh – In <br></br> Collaboration with Sonu Sood Foundation</h2>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}