import React from "react";
import "../styles/agenda.css";
import Layout from "../component/Layout";


const widthOutput = window.screen.width;
const heightOutput = window.screen.height
const headerFontSize = (4 / 100) * widthOutput;
const headerContentFontSize = (1.3 / 100) * widthOutput;
const focusContentFontSize = (1.3 / 100) * widthOutput;
const donationContentHeight = (50 / 100) * heightOutput;

const headingWebFontSize = (1 / 100) * widthOutput;
const headingMobileFontSize = (2.2 / 100) * widthOutput;
const mobileHeadingFontSize = (widthOutput > 720) ? headingWebFontSize : headingMobileFontSize;

const headingWidth = (33 / 100) * widthOutput;
const headingMobileWidth = (60 / 100) * widthOutput;
const mobileHeadingWidth = (widthOutput > 720) ? headingWidth : headingMobileWidth;
const widthUnderLine = (15 / 100) * headingWidth;


const donationContentFontSize = (2.3 / 100) * widthOutput;
const donationContentMobileFontSize = (2.3 / 100) * widthOutput;
const mobiledonationContentFontSize = (widthOutput > 720) ? donationContentFontSize : donationContentMobileFontSize;
const donationContentMobileHeight = (25 / 100) * heightOutput;
const mobileDonationContentHeight = (widthOutput > 720) ? donationContentHeight : donationContentMobileHeight;

const donateBtnFontSize = (1.1 / 100) * widthOutput;
const donateBtnMobileFontSize = (2.7 / 100) * widthOutput;
const mobileDonateBtnFontSize = (widthOutput > 720) ? donateBtnFontSize : donateBtnMobileFontSize;

const doanteBtnHeight = (15 / 100) * donationContentHeight
const doanteBtnWidth = (45 / 100) * donationContentHeight

const focusEngagement = [
    {
        content: "Medical Aid Support for Chemo-therapy Care for Cancer Patients.",
        number: "01"
    },
    {
        content: "Medical Aid Support for Dialysis patients on-going basis.",
        number: "02"
    },
    {
        content: "Setting up of Toilets in Government Schools & hospitals.",
        number: "03"
    },
    {
        content: "Promoting Rural Education and Allied supports.",
        number: "04"
    },
    {
        content: "Promoting Rural Education and Allied supports.",
        number: "05"
    },
    {
        content: "Conducting Free Health Check-Up Camps.",
        number: "06"
    },
    {
        content: "Medical Aid Support for Major Surgeries.",
        number: "07"
    },
    {
        content: "Women empowerment center in Mysore.",
        number: "08"
    },
    {
        content: "Adoption of Government Schools..",
        number: "09"
    },
    {
        content: "Note book distribution in Government Schools",
        number: "10"
    },
]

export const Agenda = () => {
    return (<>
        <Layout>
            <div className="mainPAge_Agenda">
                <div className="headerMAinDIv_Agenda">
                    <div className="headingDiv_Agenda">
                        <div style={{
                            display: "flex"
                        }}>
                            <h1 style={{
                                fontSize: `${headerFontSize}px`
                            }}>Our &nbsp; </h1>
                            <div>
                                <h1 style={{
                                    fontSize: `${headerFontSize}px`
                                }}>
                                    Agenda
                                </h1>
                                <div style={{
                                    // width: `${widthUnderLine}px`
                                }} className="underLine_Agenda"></div>
                            </div>
                        </div>
                        <h3 style={{
                            fontSize: `${headerContentFontSize}px`
                        }}>M. K. Agrotech Pvt Ltd has been early adopter of a strong CSR agenda. Along with sustained economic performance and robust eco-sustainability management, we believe in the importance of social stewardship. We embrace responsibility to create appositive impact in the communities in which we work and live</h3>
                    </div>
                </div>
                <div className="focusMainDiv_Agenda">
                    <div className="focusInnerMainDiv_Agenda">
                        <div className="focusHeadingMainDiv_Agenda">
                            <h1>
                                Our Focus Area of engagement
                            </h1>
                            <div className="underLineFocus_Agenda"> </div>
                        </div>
                        <div className="focusContentMainDiv_Agenda">
                            {
                                focusEngagement?.map((item, id) => {
                                    return (<>
                                        <div className="focusContentInnerMainDiv_Agenda">
                                            <div className="focusContentDiv_Agenda">
                                                <h1 style={{
                                                    fontSize: `${focusContentFontSize}px`
                                                }}>{item.content}</h1>
                                            </div>
                                            <div className="focusNumberDiv_Agenda">
                                                <h2>{item.number}</h2>
                                            </div>
                                        </div>
                                    </>)
                                })
                            }
                        </div>
                        <div className="lowwerMainDiv_Agenda">
                            <div style={{
                                height: `${mobileDonationContentHeight}px`
                            }} className="LowerinnerMainDiv_Agenda">
                                <div className="LowerinnerDiv_Agenda">
                                    <h1 style={{
                                        fontSize: `${mobiledonationContentFontSize}px`
                                    }}>
                                        Every dollar you contribute brings us one step closer to our mission's success!
                                    </h1>
                                </div>
                                <div className="donateBtn_Agenda">
                                    <button style={{
                                        fontSize: `${mobileDonateBtnFontSize}px`,
                                        height: `${doanteBtnHeight}px`,
                                        width: `${doanteBtnWidth}px`
                                    }}>
                                        Donate Now
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="lowerMainDivStartFundriser_Agenda">
                    <div className="lowerInnerMainDivStartFundriser_Agenda">
                        <div className="lowerUpperMainDivStartFundriser_Agenda">
                            <div className="middleUnderLine_Agenda"></div>
                            <h2 style={{
                                fontSize: `${mobileHeadingFontSize * 2}px`
                            }}>Do You Want To Rise Fund?</h2>
                            <div className="middleUnderLine_Agenda"></div>
                        </div>
                        <div className="lowerDownMainDivStartFundriser_Agenda">
                            <button style={{
                                fontSize: `${mobileDonateBtnFontSize}px`,
                                height: `${doanteBtnHeight *0.8}px`,
                                width: `${doanteBtnWidth*1.2}px`
                            }}>
                                Start A Fundraiser
                            </button>
                        </div>
                    </div>
                </div>
                    </div>

                </div>
            </div>
        </Layout>
    </>)
}

