import React from "react";
import Logo from '../../svg/logo.svg'
import { pagePaths } from "../../utils/constants";

const CompanyLogo = () => {
    return (
        <>
            <div>
                <a href={pagePaths.root}> <img src={Logo} /></a>
            </div>
        </>
    )
}
export default CompanyLogo;