import React, { useState } from "react";
import '../styles/footer.css'
import Logo from '../images/logo.png'
import VISA from '../images/visa.png'
import MASTERCARD from '../images/mastercard.png'
import AmericanExpress from '../images/american-express.png'
import Rupay from '../images/rupay.png'
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { BsTwitter, BsWhatsapp } from 'react-icons/bs'
import { FaLinkedinIn } from 'react-icons/fa'
import WezboCloudLogo from "../images/wezbocloudlogo.png"
import { AiOutlinePlusSquare } from "react-icons/ai"
import { useEffect } from "react";
import axios from "axios";
import { pagePaths } from "../utils/constants";
import dropDown from "../svg/arrowBottomAboutUs.svg"


const widthOutPut = window.screen.width;
const contentfontSize = (1 / 100) * widthOutPut;


const Footer = () => {
    const [workFooter, setWorkfooter] = useState(false)
    const [causeFooter, setCauseFooter] = useState(false)
    const [aboutUsFooter, setAboutUsFooter] = useState(false)
    const [supportFooter, setSupportFooter] = useState(false)
    const [dataCategories, setDataCategories] = useState([]);
    const [newsDropDown, setNewDropDown] = useState(false)

    const mobileScreenFoorter = (widthOutPut > 680);



    const fetchData_Categories = async () => {
        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/category/All`;

            const res = await axios.get(url)
            const { data } = res;
            setDataCategories(data)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData_Categories()
    }, []);


    return (
        <>
            <div className="mainContainer_footer">
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div className="mainDiv_footer">
                        <div className="upperFooter_footer">
                            <div className="logo_footer">
                                <div className="logoImg_footer">
                                    <img src={Logo} alt="Logo_footer" />
                                    {/* <hr></hr> */}
                                </div>
                                <div className="socilaLogo_footer">
                                    <AiFillFacebook size={30} />
                                    <BsTwitter size={30} />
                                    <FaLinkedinIn size={30} />
                                    <AiFillYoutube size={30} />
                                    <AiFillInstagram size={30} />
                                    <BsWhatsapp size={30} />
                                </div>
                                <div>
                                    <h2>&nbsp;</h2>
                                    <h2>1.5M +</h2><h4>&nbsp;</h4>
                                    <h5>Followers</h5><h5>&nbsp;</h5>
                                    <h5>For any queries</h5>
                                    <h5>Email: csr@masoom.in</h5>
                                    <h5>Contact No: +91 76249 96878</h5>
                                </div>
                            </div>
                            <div className="cause_footer">
                                <h2 className="mobileViewFooter">Activities  <AiOutlinePlusSquare className="addIcon" onClick={() => setCauseFooter(!causeFooter)} /></h2><h2>&nbsp;</h2>
                                <div className={causeFooter ? "mobileCauseFooter" : "cause_footerDetails"}>
                                    {/* {
                                        dataCategories?.map((item) => {
                                            return (<>
                                                <div key={item.id}>
                                                    <a
                                                        href={`browserfundrasierdropdown?id=${item.id}`}
                                                        style={{ color: "black", textDecoration: "none", lineHeight: 2 }}
                                                    >{item.name} Crowdfunding </a>
                                                </div>
                                            </>)
                                        })
                                    } */}

                                    <div>
                                        <h5 style={{
                                            fontWeight: "100",
                                            fontSize: `${contentfontSize}px`
                                        }}>
                                            <a
                                                // href={`browserfundrasierdropdown?id=${item.id}`}
                                                href={pagePaths.activitiesSubEduction}
                                                style={{ color: "black", textDecoration: "none", lineHeight: 2, fontWeight: "100", }}
                                            >Education</a>
                                        </h5>
                                        {/* <h3>&nbsp;</h3> */}

                                        <h5 style={{
                                            fontWeight: "100",
                                        }}>
                                            <a
                                                // href={`browserfundrasierdropdown?id=${item.id}`}
                                                href={pagePaths.activitiesSubHealth}
                                                style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px`, lineHeight: 2 }}
                                            >Health Care & Wellness</a>
                                        </h5>
                                        {/* <h3>&nbsp;</h3>  */}
                                        <h5 style={{
                                            fontWeight: "100",
                                        }}>
                                            <a
                                                // href={`browserfundrasierdropdown?id=${item.id}`}
                                                href={pagePaths.activitiesSubCulture}
                                                style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px`, lineHeight: 2 }}
                                            >Culture</a>
                                        </h5>
                                        {/* <h3>&nbsp;</h3> */}
                                        <h5 style={{
                                            fontWeight: "100",
                                        }}>
                                            <a
                                                // href={`browserfundrasierdropdown?id=${item.id}`}
                                                href={pagePaths.activitiesSubHumanitarian}
                                                style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px`, lineHeight: 2 }}
                                            >Humanitarian</a>
                                        </h5>
                                        {/* <h3>&nbsp;</h3> */}
                                    </div>
                                </div>

                            </div>
                            <div className="work_footer">
                                <h2 className="mobileViewFooter">How it works?  <AiOutlinePlusSquare className="addIcon" onClick={() => setWorkfooter(!workFooter)} /></h2><h2>&nbsp;</h2>
                                <div className={workFooter ? "mobileWorkFooter" : "work_footerDetails"}>
                                    <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a
                                            href={pagePaths.sponsorChild}
                                            style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px`, lineHeight: 2 }}
                                        >Sponsor A Child</a>
                                    </h5>
                                    <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a
                                            href={pagePaths.fundraiserTips}
                                            style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px`, lineHeight: 2 }}
                                        >Fundraising Tips</a>
                                    </h5>

                                    <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a
                                            href={pagePaths.whatiscrowfunding}
                                            style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px`, lineHeight: 2 }}
                                        >What is Crowdfunding?</a>
                                    </h5>
                                    <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a
                                            href={pagePaths.withdrawFund}
                                            style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px`, lineHeight: 2 }}
                                        > Withdraw Fund</a>
                                    </h5>
                                    <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a
                                            href={pagePaths.browserfundrasier}
                                            style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px`, lineHeight: 2 }}
                                        >Browse Fundraiser</a>
                                    </h5>
                                </div>
                            </div>
                            <div className="aboutus_footer">
                                <h2 className="mobileViewFooter">About Us  <AiOutlinePlusSquare className="addIcon" onClick={() => setAboutUsFooter(!aboutUsFooter)} /></h2><h2>&nbsp;</h2>
                                <div className={aboutUsFooter ? "mobileAbutUsFooter" : "aboutus_footerDetails"}>

                                    <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a href={pagePaths.aboutusFooterMasoomTrust} style={{ color: "black", textDecoration: "none", lineHeight: 2, fontSize: `${contentfontSize}px` }}>About Us</a>
                                    </h5>

                                    <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a href={pagePaths.agenda} style={{ color: "black", textDecoration: "none", lineHeight: 2, fontSize: `${contentfontSize}px` }}> Masoom Trust Agenda</a>
                                    </h5>

                                    <div
                                        onClick={() => setNewDropDown(!newsDropDown)}
                                        style={{
                                            display: "flex",
                                            cursor: "pointer"
                                        }}>
                                        <h5 style={{
                                            fontWeight: "100",
                                            width: "50%"
                                        }}
                                        >In The News

                                        </h5>
                                        {
                                            newsDropDown ? <img style={{
                                                transform: "rotate(180deg)"
                                            }} src={dropDown} /> : <img src={dropDown} />
                                        }
                                    </div>

                                    {
                                        newsDropDown ? <div>

                                            <h5 className="dropDownNews_Footer">
                                                <a
                                                    href={pagePaths.masoomTrustActivities}
                                                    style={{
                                                        textDecoration: "none",
                                                        // color: "#7a7a7a"
                                                    }}
                                                >
                                                    Masoom Trust Activities
                                                </a>
                                            </h5>
                                            <h5 className="dropDownNews_Footer">
                                                <a
                                                    href={pagePaths.otherActivities}
                                                    style={{
                                                        textDecoration: "none",
                                                        // color: "#7a7a7a"
                                                    }}
                                                >
                                                    Other Activities
                                                </a>
                                            </h5>
                                            <h5 className="dropDownNews_Footer">
                                                <a
                                                    href={pagePaths.newInitiative}
                                                    style={{
                                                        textDecoration: "none",
                                                        // color: "#7a7a7a"
                                                    }}
                                                >
                                                    New Initiatives
                                                </a>
                                            </h5>
                                            <h5 className="dropDownNews_Footer">
                                                <a
                                                    href={pagePaths.womenEmpowerment}
                                                    style={{
                                                        textDecoration: "none",
                                                        // color: "#7a7a7a"
                                                    }}
                                                >
                                                    Women Empowerment
                                                </a>
                                            </h5>
                                            <h5 className="dropDownNews_Footer">
                                                <a
                                                    href={pagePaths.tuberculsios}
                                                    style={{
                                                        textDecoration: "none",
                                                        // color: "#7a7a7a"
                                                    }}
                                                >
                                                    TB Patient
                                                </a>
                                            </h5>
                                        </div> : null
                                    }
                                    <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a href={pagePaths.sucessStoriesFooter} style={{ color: "black", textDecoration: "none", lineHeight: 2, fontSize: `${contentfontSize}px` }}> Success Stories</a>
                                    </h5>
                                </div>
                            </div>
                            <div className="support_footer">
                                <h2 className="mobileViewFooter">Support  <AiOutlinePlusSquare className="addIcon" onClick={() => setSupportFooter(!supportFooter)} /></h2><h2>&nbsp;</h2>
                                <div className={supportFooter ? "mobileSupportFooter" : "support_footerDetails"}>
                                    {/* <a
                                        href={pagePaths.medicalSupport}
                                        style={{ color: "black", textDecoration: "none", fontSize: `${contentfontSize}px` }}
                                    >
                                        <h5 style={{
                                            fontWeight: "100",
                                            lineHeight: 2
                                        }}>Medical Finance</h5>
                                    </a>
                                    <h5 style={{
                                        fontWeight: "100",
                                        lineHeight: 2
                                    }}>FAQs & Help Center</h5>
                                    <h5 style={{
                                        fontWeight: "100",
                                        lineHeight: 2
                                    }}>Are Masoom Trusts Genuine?</h5> */}
                                    {/* <h5 style={{
                                        fontWeight: "100",
                                        lineHeight: 2
                                    }}>Fundraiser Video</h5> */}
                                     <h5 style={{
                                        fontWeight: "100",
                                    }}>
                                        <a href={pagePaths.certificate} style={{ color: "black", textDecoration: "none", lineHeight: 2, fontSize: `${contentfontSize}px` }}> Certificate</a>
                                    </h5>
                                    <h5 style={{
                                        fontWeight: "100",
                                        lineHeight: 2
                                    }}>Trust & Safety</h5>
                                    <h5 style={{
                                        fontWeight: "100",
                                        lineHeight: 2
                                    }}>Contact Us</h5>
                                    <h5 style={{
                                        fontWeight: "100",
                                        lineHeight: 2
                                    }}>For any queries<br></br>
                                        Contact Email Id : csr@masoom.in<br></br>
                                        Contact Mobile Number / Chat with us  : +91 76249 96878</h5>
                                </div>
                            </div>
                        </div>
                        <div className="paymentImg_footer">
                            <img src={VISA} alt="Visa_footer" />
                            <img src={MASTERCARD} alt="MasterCard_footer" />
                            <img src={AmericanExpress} alt="American Express_footer" />
                            <img src={Rupay} alt="Rupay_footer" />

                        </div>
                    </div>
                </div>
                <div className="copyRight">
                    <h3>Copyright @2023 Masoom Trust, Made with ❤️&nbsp; </h3>
                    <a href="https://wezbocloud.com/"><img src={WezboCloudLogo} className="wezbocloudImg" /></a>
                </div>
            </div>
        </>
    )
}
export default Footer;
