import React from "react";
import FootStep from '../svg/footStep.svg'
import Step1Icon from '../svg/step1Icon.svg'
import Step2Icon from '../svg/step2Icon.svg'
import Step3Icon from '../svg/step3Icon.svg'
import Rect_Img from '../images/Rectangle12.png'
import '../styles/simpleStep.css'
import SimpleStepShade from '../svg/simpleStepDesign.svg'
import SimpleStepShade2 from '../svg/simpleStepDesign2.png'
import { pagePaths } from "../utils/constants";


const widthOutPut = window.screen.width;
const headerMainFontSize = (5 / 100) * widthOutPut;


const SimpleStep = () => {
    return (
        <>
            <div>
                <img src={SimpleStepShade} className="SimpleStepShade" />
                <div className="headerSimpleStep">
                    <h1 style={{
                        fontSize:`${headerMainFontSize}px`
                    }} className="headerSimpleStepH1">Start a Fundraiser in three simple steps</h1>
                </div>
                <div>
                    <div className="step1">
                        <div className="footStep1RightDiv" >
                            <img src={FootStep} className="footStep1RightUpper" />
                            <img src={FootStep} className="footStep1RightUpper" />
                            <img src={FootStep} className="footStep1RightUpper" />
                        </div>
                        <h1 className="step1Heading">1.</h1>
                        <div className="step1_Heading">
                            <div className="step1Header" style={{ display: "flex" }}>
                                <h1>Start your fundraiser</h1> &nbsp;
                                <img src={Step1Icon} className="step1Icon" />
                            </div>
                            <h4>It’ll take only 2 minutes.
                                Just tell us a few details about you and the ones you are raising funds for.</h4>
                        </div>
                        <div className="footStep1">
                            <img src={FootStep} className="footStep1Right" />
                            <img src={FootStep} className="footStep1Right" />
                            <img src={FootStep} className="footStep1Right" />
                            {/* <img src={FootStep} className="footStep1Right" /> */}
                            {/* <img src={FootStep} className="footStep1Right" /> */}
                            <div>
                                <img src={FootStep} className="rotateFoot1" />
                                <img src={FootStep} className="rotateFoot2" />
                                <img src={FootStep} className="rotateFoot_3" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="step2Main">
                            <div className="step2" >
                                <h1 className="step1Heading">2.</h1>
                                <div className="step2_Heading" >
                                    <div className="step2Header" style={{ display: "flex" }}>
                                        <h1>Share your fundraiser</h1> &nbsp;&nbsp;
                                        <img src={Step2Icon} className="step1Icon" />
                                    </div>
                                    <h4>All you need to do is share the fundraiser with your friends and family.
                                        In no time, support will start pouring in.</h4>
                                    <p>Share your fundraiser directly from dashboard on social media.</p>
                                </div>
                                <div className="footStep2Rotate">
                                    <img src={FootStep} className="rotateFoot3" />
                                    {/* <img src={FootStep} className="rotateFoot3" /> */}
                                    <img src={FootStep} className="rotateFoot3 rotateFoot3MobileView" />
                                    <img src={FootStep} className="rotateFoot3" />
                                    <img src={FootStep} className="rotateFoot3" />
                                    <img src={FootStep} className="rotateFoot3" />
                                </div>

                            </div>
                        </div>
                        <div style={{ marginTop: "-4%", marginLeft: "10%" }} >
                            <img src={FootStep} className="footLeft1" />
                        </div>
                        <div style={{ marginTop: "2%", marginLeft: "11%" }}>
                            <img src={FootStep} className="footLeft2" />
                            <img src={FootStep} className="footLeft3" />
                            <img src={FootStep} className="footLeft3" />
                            <img src={FootStep} className="footLeft3" />
                            <img src={FootStep} className="footLeft3" />
                            <img src={FootStep} className="footLeft3" />
                        </div>
                        <div className="step3Main" style={{ marginTop: "-4%", }}>
                            <div className="step3">
                                <div className="step3Inner">
                                    <div className="step1Header step3Header" style={{ display: "flex" }}>
                                        <h1 style={{ textDecoration: "none" }} className="step1Heading step3Heading">3.</h1>
                                        <h1>Withdraw Funds</h1> &nbsp;&nbsp;
                                        <img src={Step3Icon} className="step1Icon" />
                                    </div>
                                    <h4>The funds raised can be withdrawn without any hassle directly to your bank account.</h4>
                                    <p>It takes only 5 minutes to withdraw funds on ketto.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="stepLowerDiv">
                    <div className="StepLowerDivBtn">
                        <a href={pagePaths.signup} className="startBtn">
                            START A FUNDRAISERS FOR FREE
                        </a>
                        <button className="talkBtn">
                            TALK TO US
                        </button>
                    </div>
                    <div className="chatBtn">
                        <h4>Have any questions for us? Chat with our team on Facebook or WhatsApp now.</h4>
                        <button className="chatUSBtn">CHAT WITH US</button>
                    </div>
                </div>
                <img src={SimpleStepShade2} className="SimpleStepShade2" />
                <div>
                    <img src={Rect_Img} style={{ width: "100%", marginTop: "2%" }} />
                </div>
            </div>
        </>
    )
}
export default SimpleStep