import React from "react";
import "../styles/newInitiative.css";
import Layout from "../component/Layout";


const widthOutput = window.screen.width;
const heightOutput = window.screen.height
const headerFontSize = (4 / 100) * widthOutput;
const headerContentFontSize = (1.3 / 100) * widthOutput;
const focusContentFontSize = (1.3 / 100) * widthOutput;
const donationContentHeight = (50 / 100) * heightOutput;

const headingWebFontSize = (1 / 100) * widthOutput;
const headingMobileFontSize = (2.2 / 100) * widthOutput;
const mobileHeadingFontSize = (widthOutput > 720) ? headingWebFontSize : headingMobileFontSize;

const headingWidth = (33 / 100) * widthOutput;
const headingMobileWidth = (60 / 100) * widthOutput;
const mobileHeadingWidth = (widthOutput > 720) ? headingWidth : headingMobileWidth;
const widthUnderLine = (15 / 100) * headingWidth;


const donationContentFontSize = (2.3 / 100) * widthOutput;
const donationContentMobileFontSize = (2.3 / 100) * widthOutput;
const mobiledonationContentFontSize = (widthOutput > 720) ? donationContentFontSize : donationContentMobileFontSize;
const donationContentMobileHeight = (25 / 100) * heightOutput;
const mobileDonationContentHeight = (widthOutput > 720) ? donationContentHeight : donationContentMobileHeight;

const donateBtnFontSize = (1.1 / 100) * widthOutput;
const donateBtnMobileFontSize = (2.7 / 100) * widthOutput;
const mobileDonateBtnFontSize = (widthOutput > 720) ? donateBtnFontSize : donateBtnMobileFontSize;

const doanteBtnHeight = (15 / 100) * donationContentHeight
const doanteBtnWidth = (45 / 100) * donationContentHeight

const focusEngagement = [
    {
        content: "Drinking water Facility – RO – All Taluks Bus Stands of Mandya and Mysore – 15",
        number: "01"
    },
    {
        content: "Tree Plantation Drive – 1 Lakh saplings – Plant locations",
        number: "02"
    },
    {
        content: "English Medium School at Mysore with Old Age Home",
        number: "03"
    },
    {
        content: "Blood Bank Van – Donation of New Blood Bank Van to MIMS, Mandya",
        number: "04"
    },
    {
        content: "Free Ambulance Services – Mandya – Mysore",
        number: "05"
    },
]

export const NewInitiative = () => {
    return (<>
        <Layout>
            <div className="mainPAge_NewInitiative">
                <div className="headerMAinDIv_NewInitiative">
                    <div className="headingDiv_NewInitiative">
                        <div style={{
                            display: "flex"
                        }}>
                            <h1 style={{
                                fontSize: `${headerFontSize}px`
                            }}>New &nbsp; </h1>
                            <div>
                                <h1 style={{
                                    fontSize: `${headerFontSize}px`
                                }}>
                                    initiatives
                                </h1>
                                <div style={{
                                    // width: `${widthUnderLine}px`
                                }} className="underLine_NewInitiative"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="focusMainDiv_NewInitiative">
                    <div className="focusInnerMainDiv_NewInitiative">
                        <div className="focusHeadingMainDiv_NewInitiative">
                            <h1>
                                our initiative in particular area
                            </h1>
                            <div className="underLineFocus_NewInitiative"> </div>
                        </div>
                        <div className="focusContentMainDiv_NewInitiative">
                            {
                                focusEngagement?.map((item, id) => {
                                    return (<>
                                        <div className="focusContentInnerMainDiv_NewInitiative">
                                            <div className="focusContentDiv_NewInitiative">
                                                <h1 style={{
                                                    fontSize: `${focusContentFontSize}px`
                                                }}>{item.content}</h1>
                                            </div>
                                            <div className="focusNumberDiv_NewInitiative">
                                                <h2>{item.number}</h2>
                                            </div>
                                        </div>
                                    </>)
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    </>)
}

