import React, { useState } from "react";
import '../styles/howItWork.css'
import FirstImage from '../images/1.png'
import SecondImage from '../images/2.png'
import Logo from '../images/logo.png'
import { BiSolidUser } from 'react-icons/bi'
import { MdSendToMobile } from 'react-icons/md'
import { AiFillCaretDown } from 'react-icons/ai'
import FourthImage from '../images/4.png'
import FifthImage from '../images/5.png'
import SeventhImage from '../images/7.png'
import ToolBar from "../component/toolbar/toolbar";
import FootStep from '../svg/footStep.svg'
import Step1Icon from '../svg/step1Icon.svg'
import Step2Icon from '../svg/step2Icon.svg'
import Step3Icon from '../svg/step3Icon.svg'
import SimpleStepShade from '../svg/simpleStepDesign.svg'

import Payout from "../component/howItWork/payout/payout";
import Footer from "./footer";
import UpperShade from '../svg/howitworkShadeUpper.svg';
import UpperLeftShade from '../svg/howitworkUpperLeftShade.svg'
import { pagePaths } from "../utils/constants";

const widthOutPut = window.screen.width;
const headerMainFontSize = (4 / 100) * widthOutPut;
const headerMain1FontSize = (4 / 100) * widthOutPut;

const stepsFontSize = (2.5 / 100) * widthOutPut;
const stepsContentFontSize = (1.5 / 100) * widthOutPut;
const stepsContentPFontSize = (1 / 100) * widthOutPut;

const iconWidthHeight = (2.93 / 100) * widthOutPut;

const step1HeadingWidth = (28.5 / 100) * widthOutPut;
const step2HeadingWidth = (30 / 100) * widthOutPut;
const step3HeadingWidth = (24.5 / 100) * widthOutPut;


export const HowItWork = () => {
    const [inputData, setInputData] = useState({
        username: "",
        mobile: "",
        fundfor: "",
    })

    const handleChange = (e) => {
        const value = e.target.value;
        setInputData({
            ...inputData,
            [e.target.name]: value
        });
    };
    return (
        <>
            <div>
                <ToolBar />
                <img src={UpperShade} className="UpperShade" />
                <div className="bodyHeading">
                    <div className="bodyHeadingInner">
                        <h1 style={{
                            fontSize: `${headerMainFontSize}px`
                        }}>
                            Raising Funds was never this easy. Start a <br></br>fundraiser in 5 minutes!
                        </h1>
                    </div>
                </div>
                <div>
                    <div className="containAndSignIn">
                        <div className="containAndSignIn_Contain_upper_Main">
                            <div className="containAndSignIn_Contain_upper">
                                <div className="containAndSignIn_Contain_upper_main_1">
                                    <div className="containAndSignIn_Contain_upper_1">
                                        <div className="containAndSignIn_Contain_upper_1_1">
                                            <div>
                                                <h4>20 Lakh+</h4>
                                            </div>
                                            <div>
                                                <h6>
                                                    DONER
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="containAndSignIn_Contain_upper_1_1">
                                            <div>
                                                <h4>1 Lakh+</h4>
                                            </div>
                                            <div>
                                                <h6>
                                                    FUNDRASIER
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="containAndSignIn_Contain_upper_1_1" style={{ border: "none" }}>
                                            <div>
                                                <h4>0%</h4>
                                            </div>
                                            <div>
                                                <h6>
                                                    PLATFORM FEE
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="containAndSignIn_Contain_upper_main_photo">
                                        <img src={FirstImage} alt="First Image" />
                                    </div>
                                </div>
                                <div>
                                    <div className="containAndSignIn_Contain_upper_main_2">
                                        <button className="containAndSignIn_Contain_upper_main_1_button">
                                            Donate Now
                                        </button>
                                    </div>
                                </div>
                                <div className="containAndSignIn_Contain_upper_main_3Div">
                                    <div className="containAndSignIn_Contain_upper_main_3">
                                        <div className="containAndSignIn_Contain_upper_main_3Inner"><img src={SecondImage} alt="Second Image" /></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="containAndSignIn_SignIn">
                            <div className="containAndSignIn_SignIn_Inner_Main">
                                <div className="containAndSignIn_SignIn_Header">
                                    <div>
                                        <img src={Logo} alt="Logo" />
                                    </div>
                                    <div>
                                        <h1>We know you are in urgent need of funds.</h1>
                                    </div>
                                    <div>
                                        <h5>Our personal fundraiser expert is waiting to get you started!</h5>
                                    </div>
                                </div>
                                <div className="containAndSignIn_SignIn_SignIn">
                                    <div className="userName_mobile_fund">
                                        <div className="userName_icon">
                                            <BiSolidUser />
                                        </div>
                                        <input
                                            type="text"
                                            name="username"
                                            value={inputData.username}
                                            onChange={handleChange}
                                            className="userName_label"
                                            placeholder="Username"
                                        >
                                        </input>
                                    </div>
                                    <div className="userName_mobile_fund">
                                        <div className="userName_icon">
                                            <MdSendToMobile />
                                        </div>
                                        <input
                                            type="number"
                                            name="mobile"
                                            value={inputData.mobile}
                                            onChange={handleChange}
                                            className="userName_label"
                                            placeholder="Enter Your Mobile Number"
                                        ></input>
                                    </div>
                                    <div className="userName_mobile_fund">
                                        <div className="userName_icon">
                                            <AiFillCaretDown />
                                        </div>
                                        <input
                                            type="text"
                                            name="fundfor"
                                            value={inputData.fundfor}
                                            onChange={handleChange}
                                            className="userName_label"
                                            placeholder="What will be Fund use for?"
                                        ></input>
                                    </div>
                                    <div className="containAndSignIn_SignIn_SignIn_button">
                                        <div className="containAndSignIn_SignIn_SignIn_button_para">
                                            <p>
                                                102 People started a fundraiser in last 2 days
                                            </p>
                                        </div>
                                        <button className="btn_whatssapp">CONNTECT ON WHATSAPP</button>
                                        <button className="btn_getCall">GET CALL</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="detailsDonarLower">
                        <div className="detailsDonarLowerInner">
                            <div className="containAndSignIn_Contain">
                                <div className="containAndSignIn_Contain_upper">
                                    <div className="containAndSignIn_Contain_upper_main_1">
                                        <div className="containAndSignIn_Contain_upper_1">
                                            <div className="containAndSignIn_Contain_upper_1_1">
                                                <div>
                                                    <h4>12 Lakh+</h4>
                                                </div>
                                                <div>
                                                    <h6>
                                                        DONER
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="containAndSignIn_Contain_upper_1_1">
                                                <div>
                                                    <h4>2 Lakh+</h4>
                                                </div>
                                                <div>
                                                    <h6>
                                                        FUNDRASIER
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="containAndSignIn_Contain_upper_1_1" style={{ border: "none" }}>
                                                <div>
                                                    <h4>0%</h4>
                                                </div>
                                                <div>
                                                    <h6>
                                                        PLATFORM FEE
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <div className="containAndSignIn_Contain_down_main_2">
                                            <button className="containAndSignIn_Contain_down_main_1_button">
                                                Donate Now
                                            </button>
                                            <img src={FourthImage} />
                                        </div>
                                    </div>
                                    <div className="containAndSignIn_Contain_down_main_3Div">
                                        <div className="containAndSignIn_Contain_down_main_3">
                                            <div className="containAndSignIn_Contain_down_main_3Inner"><img src={SeventhImage} alt="Second Image" /></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <div className="detailsDonarLowerInnerCard">
                            <img src={Rectangle_24} />
                        </div> */}
                    </div>
                    <img style={{ position: "absolute", width: "100%", top: "100%" }} src={UpperLeftShade} />
                </div>
                <div className="crowdfunding">
                    <div className="crowdfunding_1">
                        <h1 style={{
                            fontSize: `${headerMain1FontSize}px`
                        }}>What is Crowdfunding</h1>
                        <h2>
                            In its simplest form, Crowdfunding is a practice of giving monetary funds to overcome specific social, cultural, or economic hurdles individuals face in their daily lives.
                        </h2>
                    </div>
                    <div className="crowdfunding_1_img">
                        <img src={FifthImage} alt="Fifth Image" />
                    </div>
                </div>
                <div className="monsoonTrust" style={{ marginBottom: "3%" }}>
                    <h1 style={{
                        fontSize: `${headerMain1FontSize}px`
                    }}>So how does Crowdfunding work On Masoom Trust?</h1>
                    <h2>Let us assume an individual, unfortunately, meets with an accident on the road.
                        His medical expenses and hospital bills start piling up. Now he needs ₹5 Lakh to pay his expensive medical bills.
                        Fortunately, his best friend signed up on Masoom Trust crowdfunding platform, completed the process of submitting valid documents needed for verification.
                        In a few minutes, he created a crowdfunding campaign to raise funds for his friend’s medical expenses. Now, this campaign can be shared with all his near and dear ones through WhatsApp,
                        Instagram, Twitter, Facebook and E-mail. In a matter of few minutes, funds start coming in to support the financial needs of the injured friend.</h2>
                </div>
                <img src={SimpleStepShade} className="SimpleStepShade" />
                <div>
                    <img src={SimpleStepShade} className="SimpleStepShade" />
                    <div className="headerSimpleStep">
                        <h1 style={{
                            fontSize: `${headerMain1FontSize}px`
                        }} className="headerSimpleStepH1">Start a Fundraiser in three simple steps</h1>
                    </div>
                    <div>
                        <div className="step1">
                            <div className="footStep1RightDiv" >
                                <img src={FootStep} className="footStep1RightUpper" />
                                <img src={FootStep} className="footStep1RightUpper" />
                                <img src={FootStep} className="footStep1RightUpper" />
                            </div>
                            {/* <h1 style={{
                                fontSize: `${stepsFontSize}px`, textDecoration: 'underline'
                            }} className="step1Heading">1.</h1> */}
                            <div className="step1_Heading">
                                <div style={{
                                    width: `${step1HeadingWidth}px`,
                                    display: "flex",
                                    justifyContent: "space-between"
                                }} className="step1Header">
                                    <h1 style={{
                                        fontSize: `${stepsFontSize}px`
                                    }}>1.Start your fundraiser</h1> 
                                    <img style={{
                                        width: `${iconWidthHeight}px`,
                                        height: `${iconWidthHeight}px`
                                    }} src={Step1Icon} className="step1Icon" />
                                </div>
                                <h4 style={{
                                    fontSize: `${stepsContentFontSize}px`
                                }}>It’ll take only 2 minutes.
                                    Just tell us a few details about you and the ones you are raising funds for.</h4>
                            </div>
                            <div className="footStep1">
                                <img src={FootStep} className="footStep1Right" />
                                <img src={FootStep} className="footStep1Right" />
                                <img src={FootStep} className="footStep1Right" />
                                <div>
                                    <img src={FootStep} className="rotateFoot1" />
                                    <img src={FootStep} className="rotateFoot2" />
                                    <img src={FootStep} className="rotateFoot_3" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="step2Main">
                                <div className="step2" >
                                    {/* <h1 style={{
                                        fontSize: `${stepsFontSize}px`, textDecoration: 'underline'
                                    }} className="step1Heading">2.</h1> */}
                                    <div className="step2_Heading" >
                                        <div style={{
                                            width: `${step2HeadingWidth}px`,
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }} className="step2Header">
                                            <h1 style={{
                                                fontSize: `${stepsFontSize}px`
                                            }}>2.Share your fundraiser</h1>
                                            <img style={{
                                                width: `${iconWidthHeight}px`,
                                                height: `${iconWidthHeight}px`
                                            }} src={Step2Icon} className="step1Icon" />
                                        </div>
                                        <h4 style={{
                                            fontSize: `${stepsContentFontSize}px`
                                        }}>All you need to do is share the fundraiser with your friends and family.
                                            In no time, support will start pouring in.</h4>
                                        <p style={{
                                            fontSize: `${stepsContentPFontSize}px`
                                        }}>Share your fundraiser directly from dashboard on social media.</p>
                                    </div>
                                    <div className="footStep2Rotate">
                                        <img src={FootStep} className="rotateFoot3" />
                                        <img src={FootStep} className="rotateFoot3 rotateFoot3MobileView" />
                                        <img src={FootStep} className="rotateFoot3" />
                                        <img src={FootStep} className="rotateFoot3" />
                                        <img src={FootStep} className="rotateFoot3" />
                                    </div>

                                </div>
                            </div>
                            <div style={{ marginTop: "-4%", marginLeft: "10%" }} >
                                <img src={FootStep} className="footLeft1" />
                            </div>
                            <div style={{ marginTop: "2%", marginLeft: "11%" }}>
                                <img src={FootStep} className="footLeft2" />
                                <img src={FootStep} className="footLeft3" />
                                <img src={FootStep} className="footLeft3" />
                                <img src={FootStep} className="footLeft3" />
                                <img src={FootStep} className="footLeft3" />
                                <img src={FootStep} className="footLeft3" />
                            </div>
                            <div className="step3Main" style={{ marginTop: "-4%", }}>
                                <div className="step3">
                                    <div className="step3Inner">
                                        <div style={{
                                            width: `${step3HeadingWidth}px`,
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }} className="step3Header" >
                                            <h1 style={{
                                                fontSize: `${stepsFontSize}px`, textDecoration: 'underline'
                                            }}>3.Withdraw Funds</h1>
                                            <img style={{
                                                width: `${iconWidthHeight}px`,
                                                height: `${iconWidthHeight}px`
                                            }} src={Step3Icon} className="step1Icon" />
                                        </div>
                                        <h4 style={{
                                            fontSize: `${stepsContentFontSize}px`
                                        }}>The funds raised can be withdrawn without any hassle directly to your bank account.</h4>
                                        <p style={{
                                            fontSize: `${stepsContentPFontSize}px`
                                        }}>It takes only 5 minutes to withdraw funds on ketto.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="stepLowerDiv">
                        <div className="StepLowerDivBtn">
                            <a href={pagePaths.signup} className="startBtn">
                                START A FUNDRAISERS FOR FREE
                            </a>
                            <button className="talkBtn">
                                TALK TO US
                            </button>
                        </div>
                        <div className="chatBtn">
                            <h4>Have any questions for us? Chat with our team on Facebook or WhatsApp now.</h4>
                            <button className="chatUSBtn">CHAT WITH US</button>
                        </div>
                    </div>
                    {/* <img src={SimpleStepShade2} className="SimpleStepShade2" /> */}
                    {/* <div>
                        <img src={Rect_Img} style={{ width: "100%", marginTop: "2%" }} />
                    </div> */}
                </div>
                <Payout />
                <Footer />
            </div>
        </>
    )
}