
export const pagePaths = {
    root: "/",
    login: "/login",
    signup:"/signup",
    browserfundrasier:"/browserfundraiser",
    browserfundrasierdropdown:"/browserfundrasierdropdown",
    howItWork:"/howItWork",
    forgetPassword:"/forgetPassword",
    forgotPasswordOTP:"/forgotpasswordOTP",
    aboutuspage:"/aboutuspage",
    sucessStoriesFooter:"/sucessStories",
    aboutusFooterMasoomTrust :"/aboutusMasoomTrust",
    intheNewsFooter:"/inthenews",
    fundraiserTips:"/fundraisertips",
    sponsorChild:"/sponsorchild",
    whatiscrowfunding:"/whatiscrowdfunding",
    withdrawFund :"/withdrawfund",
    medicalSupport:"/medical-support",
    activitiesSubEduction:"/activitiesSubEduction",
    activitiesSubHealth:"/activitiesSubHealth",
    activitiesSubCulture:"/activitiesSubCulture",
    activitiesSubHumanitarian:"/activitiesSuHumanitarian",
    galleriesDetailsPage:"/galleries-details",
    agenda:"/agenda",
    masoomTrustActivities:"/masoom-trust-activities",
    otherActivities:"/other-activities",
    womenEmpowerment:"/women-empowerment",
    tuberculsios:"/tuberculsios",
    certificate:"/certificate",
    newInitiative:"/NewInitiative"
}