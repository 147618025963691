import React from "react";
import '../../styles/whymasoom.css';
import Thumb from '../../svg/thumb.svg';
import Vector1 from '../../svg/vector1.svg'
import Vector2 from '../../svg/victor2.svg';
import User from '../../svg/user.svg';
import Tools from '../../svg/tools.svg';
import ReciverMoney from '../../svg/ReciveMoney.svg'
import Messege from '../../svg/messege.svg'
import DataBoard from '../../svg/dataBoard.svg'
import FundDonation from '../../svg/fundDonation.svg'
import PaymentSupport from '../../svg/paymentSupport.svg'


const widthOutPut = window.screen.width;
const headerMainFontSize = (5 / 100) * widthOutPut;

const WhyMasoom = () => {
    return (
        <>
            <div className="upperMainDiv_whyMasoom">
                <div className="mainDiv_whyMasoom">
                    <div className="header_whyMasoom">
                        <h1 style={{
                            fontSize: `${headerMainFontSize}px`
                        }}>Why Masoom Trust?</h1>
                    </div>
                    <div>
                        <div>
                            <div className="symbolize1_whyMasoom">
                                <img src={Thumb} className="symbolize_img_whyMasoom" />
                                <h2>Industry’s best fundraising success rate</h2>
                            </div>
                            <img src={Vector1} className="victor1_whyMasoom" />
                            <div className="symbolize2_whyMasoom">
                                <img src={User} className="symbolize_img_whyMasoom" />
                                <h2>Supported By 55,00,000+ Donors</h2>
                            </div>
                            <img src={Vector2} className="victor2_whyMasoom" />
                            <div className="symbolize3_whyMasoom">
                                <h2>Easy-To-Manage Tools To Boost Results</h2>
                                <img src={Tools} className="symbolize_img_whyMasoom" />
                            </div>
                            <img src={Vector1} className="victor1_whyMasoom" />
                            <div className="symbolize2_whyMasoom">
                                <img src={ReciverMoney} className="symbolize_img_whyMasoom" />
                                <h2>Receive donations via all popular payment modes</h2>
                            </div>
                            <img src={Vector2} className="victor2_whyMasoom" />
                            <div className="symbolize3_whyMasoom">
                                <h2>Get Expert Support 24/7</h2>
                                <img src={Messege} className="symbolize_img_whyMasoom" />
                            </div>
                            <img src={Vector1} className="victor1_whyMasoom" />
                            <div className="symbolize2_whyMasoom">
                                <img src={DataBoard} className="symbolize_img_whyMasoom" />
                                <h2>A Dedicated Smart-Dashboard</h2>
                            </div>
                            <img src={Vector2} className="victor2_whyMasoom" />
                            <div className="symbolize3_whyMasoom">
                                <h2>Withdraw Funds Without Hassle</h2>
                                <img src={FundDonation} className="symbolize_img_whyMasoom" />
                            </div>
                            <img src={Vector1} className="victor1_whyMasoom" />
                            <div className="symbolize2_whyMasoom mobileSymbolize2">
                                <img src={PaymentSupport} />
                                <h2>International Payment Support</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WhyMasoom;