import React from "react";
import "../styles/womenEmpowerment.css";
import Layout from "../component/Layout";
import WomenEmpowermentImg1 from "../images/womenEmpowermentImg1.png"

const widthOutput = window.screen.width;
const heightOutput = window.screen.height
const headerFontSize = (4 / 100) * widthOutput;

export const WomenEmpowerment = () => {
    return (<>
        <Layout>
            <div className="mainPAge_WomenEmpowerment">
                <div className="headerMAinDIv_WomenEmpowerment">
                    <div className="headingDiv_WomenEmpowerment">
                        <div style={{
                            display: "flex"
                        }}>
                            <h1 style={{
                                fontSize: `${headerFontSize}px`,
                                textTransform: "capitalize"
                            }}>women&nbsp; </h1>
                            <div>
                                <h1 style={{
                                    fontSize: `${headerFontSize}px`,
                                    textTransform: "capitalize"
                                }}>
                                    empowerment
                                </h1>
                                <div style={{
                                    // width: `${widthUnderLine}px`
                                }} className="underLine_WomenEmpowerment"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ContentMainDiv_WomenEmpowerment">
                    <div className="ContentInnerMainDiv_WomenEmpowerment">
                        <div className="contentImgMainDiv_WomenEmpowerment">
                            <img src={WomenEmpowermentImg1} />
                        </div>
                        <div className="contentDiv_WomenEmpowerment">
                            <h1 style={{
                                fontSize: `${headerFontSize * 0.4}px`
                            }}>jute bag manufacturing center
                            </h1>
                            <h2 style={{
                                fontSize: `${headerFontSize * 0.30}px`,
                                paddingBottom:"0.5rem"
                            }}>Women are taught how to make Jute Bags, we sell, they<br></br> earn and we empower them for their Livelihood.<br></br>
                            </h2>
                            <h2 style={{
                                fontSize: `${headerFontSize * 0.30}px`
                            }}>Jute Bag Centres would be spread across Mysore & Mandya<br></br> for empowering Women.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}