import React, { useEffect, useState } from "react";
import "../styles/abousUsPage.css"
import ArrowDown from "../svg/arrowBottomAboutUs.svg"
import FacebookIcon from "../images/facebookIcon.png"
import WhassAppIcon from "../images/whatsappIcon.svg"
import Layout from "../component/Layout";
import axios from "axios";
import AliceCarousel from "react-alice-carousel";
import PrevBtn from '../images/prev.png'
import NextBtn from '../images/nxt.png'
import { ProgressBar, ProgressBar1 } from "./progressbar";
import { useSearchParams } from "react-router-dom";



const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
};

const donationNumber = 1;
let donationlength = 0;

export const SponsorChild = () => {
    const [dataDonation, setDataDonation] = useState([])
    const [next, setNext] = useState(donationNumber);
    const [activeLoad, setActiveLoad] = useState(false)

    const [searchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const id = params.id

    const fetchData_Donation = async () => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/site/campaign/2`;

            const res = await axios.get(url)
            const { data } = res
            setDataDonation([data])
        } catch (error) {

        }
    }

    const handleMoreSlide = () => {
        setNext(next + donationNumber)
        setActiveLoad(true)
    }
    const handleLessSlide = () => {
        setNext(donationNumber)
        setActiveLoad(false)

    }

    useEffect(() => {
        fetchData_Donation()
    }, [])


    console.log("checking Active load", activeLoad)

    return (
        <>
            <Layout>
                <div className="mainDiv_AboutUsPage">
                    <div className="innerMainDiv_AboutUsPage">
                        <div className="inner1MainDiv_AboutUsPage">
                            <div className="upperMainImgDiv_AboutUsPage">
                                {
                                    dataDonation?.map((item) => {
                                        <AliceCarousel infinite autoPlay={true} autoPlayInterval={7000} responsive={responsive} disableDotsControls renderPrevButton={() => {
                                            return <img src={PrevBtn} className="prevSvg_AboutUs" />
                                        }} renderNextButton={() => {
                                            return <img src={NextBtn} className="nxtSvg_AboutUs" />
                                        }} >


                                            return (<>
                                                <div key={item.id} className="sliderImgMainDiv">
                                                    <div className="sliderImgMain">
                                                        <img src={item.image1} className="sliderimg" />
                                                    </div>
                                                </div>

                                            </>)


                                        </AliceCarousel>
                                    })
                                }
                            </div>
                            <div className="upperDivConatin_AboutUsPage">
                                <div className="header_AboutUsPage">
                                    <h3>About The Fundraiser</h3>
                                </div>
                                {
                                    dataDonation?.map((item) => {
                                        return (<>
                                            <div key={item.id} className="post_AboutUsPage">
                                                <div className="postUpper_AboutUsPage">
                                                    <h4 className="post1_bold_AboutUsPage">Post By: {item.beneficiaryName}&nbsp;</h4>
                                                </div>
                                                <div className="postDown_AboutUsPage">
                                                    <h4 className="post2_1_bold_AboutUsPage">Beneficiary Name: {item.beneficiaryName} &nbsp;</h4>
                                                </div>
                                            </div>
                                            <div className="contain_AboutUsPage">
                                                <p>{item.description}</p>

                                            </div>
                                        </>)
                                    })
                                }
                                <div className="btnMainDiv_AboutUsPage">
                                    <div className="btnInnerDiv_AboutUsPage">
                                        <button className="btnWhastapp_AboutUsPage"><img src={WhassAppIcon} /> Share</button>
                                        <button className="btnFaceBook_AboutUsPage"><img src={FacebookIcon} /> Share</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inner2MainDiv_AboutUsPage">
                            {
                                dataDonation?.map((item) => {
                                    let createDate = item.createdAt
                                    let finalCreateDate = createDate.slice(0, 10)
                                    const newDate = new Date();
                                    let donateionEndDate = item.donationEndDate;

                                    let finalEndDonation = new Date(donateionEndDate.slice(0, 10));

                                    let Difference_In_Time = finalEndDonation.getTime() - newDate;

                                    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                    let campaingEndDays = Math.round(Difference_In_Days)

                                    let campaignDaysLeft = () => {
                                        if (campaingEndDays <= 0) {
                                            return "Expires"
                                        } else {
                                            return campaingEndDays;
                                        }
                                    }

                                    const progressBarPercent = (item.raisedAmount / item.goalAmount) * 100;
                                    const progressBarMaxWidth = () => {
                                        if (progressBarPercent > 100) {
                                            return 100
                                        } else {
                                            return progressBarPercent
                                        }
                                    }

                                    return (<>
                                        <div key={item.id}>
                                            <div>
                                                <h2>CHILD WELFARE</h2>
                                                <h5>Posted on: {finalCreateDate}</h5>
                                            </div>
                                            <div className="lowerMoneyValue_AboutUsPage">
                                                <h2 className="lowerMoneyValueh1_AboutUsPage">₹{item.raisedAmount}</h2>
                                                <p>raised out of <strong>₹{item.goalAmount}</strong> Goal</p>
                                            </div>
                                            <div className="lowerIndicatorDiv_AboutUsPage">

                                                <ProgressBar1 progressBarPercent={progressBarMaxWidth()} />
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div>
                                                        <h3>{item.donation?.length}</h3>
                                                        <p>DONATIONS</p>
                                                    </div>
                                                    <div style={{ textAlign: "end" }}>
                                                        <h3>{campaignDaysLeft()}</h3>
                                                        <p>DAYS LEFT</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lowerDivBtn_AboutUsPage">
                                                <button>DONATE NOW</button>
                                            </div>
                                        </div>
                                    </>)
                                })
                            }




                            <div className="inner2LowerMainDiv_AboutUsPage">
                                <div className="lower2Header_AboutUsPage">
                                    <h2>All Donations<br></br> <p style={{fontSize:"0.8rem",fontWeight:"200"}}>Total Donations {donationlength} </p> </h2>
                                </div>

                                <div className="lowerDonationSummery_AboutUsPage">
                                    {
                                        dataDonation?.map((item) => {

                                            return (<>
                                                {
                                                    item.donations?.slice(0, next)?.map((items) => {
                                                        donationlength = item.donations.length;
                                                        console.log("inside dataDonations", item, "<<<===>>>", items.lname, item.donations.length)
                                                        return (<>
                                                            <div key={items.id} className="donorNameMainDiv_AboutUsPage">
                                                                <div className="donorName_AboutUsPage" >
                                                                    <div className="donorNameProfileImg_AboutUsPage">
                                                                        <img style={{ width: "100%" }} src={items.profileImage} />
                                                                    </div>
                                                                    <div className="donorNameUsername_AboutUsPage">
                                                                        <h5>{items.fname} {items.lname}</h5>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <h5>{items.email}</h5>
                                                                </div>
                                                            </div>
                                                        </>)
                                                    })
                                                }
                                            </>)
                                        })
                                    }
                                </div>
                                <div style={{ width: "100%", textAlign: "center", padding: "1rem" }}>
                                    <div>
                                        {next < donationlength ? (
                                            <button
                                                onClick={handleMoreSlide}
                                                style={{ border: "none", background: "transparent", cursor: "pointer" }}
                                            >
                                                View More <img src={ArrowDown} />
                                            </button>
                                        ) : <button
                                            onClick={handleLessSlide}
                                            style={{ border: "none", background: "transparent", cursor: "pointer" }}
                                        >
                                            {/* {activeLoad ? "View Less" : ""} &nbsp;<img src={ArrowDown} style={{ transform: "rotate(180deg)" }} /> */}
                                            {activeLoad ? <div>View Less &nbsp;<img src={ArrowDown} style={{ transform: "rotate(180deg)" }} /></div> : ""}
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}





