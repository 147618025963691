import React from "react";
import "../styles/aboutUsFooter.css"
import Layout from "../component/Layout";
import CultureIcon from "../svg/activityCulture.svg";
import Img1 from "../images/cultureImg1.png"
import Img2 from "../images/cultureImg2.png"
import LowerImg from "../svg/activitiesLowerImg.svg";
import CultureDevelopmentIcon from "../svg/cultureDevelopmentIcon.svg"
import HumanitarianServicesIcon from "../svg/servicesHumanitarianIcon.svg";
import DrinkingServicesIcon from "../svg/servicesDrinkingIcon.svg";
import EductionServicesIcon from "../svg/servicesEductioncon.svg";
import HealthServicesIcon from "../svg/servicesHealthIcon.svg";


const widthOutPut = window.screen.width;
const heightOutput = window.screen.height;
const headingWidth = (33 / 100) * widthOutPut;
const headingMobileWidth = (60 / 100) * widthOutPut;
const mobileHeadingWidth = (widthOutPut > 720) ? headingWidth : headingMobileWidth;

const headingWebFontSize = (1 / 100) * widthOutPut;
const headingMobileFontSize = (2.2 / 100) * widthOutPut;
const mobileHeadingFontSize = (widthOutPut > 720) ? headingWebFontSize : headingMobileFontSize;


const widthUnderLine = (15 / 100) * headingWidth;

const iconWidth = (3.19 / 100) * widthOutPut;
const iconMobileWidth = (8.19 / 100) * widthOutPut;
const iconHeight = (4.23 / 100) * widthOutPut;
const iconMobileHeight = (8.23 / 100) * widthOutPut;

const mobileIconWidth = (widthOutPut > 720) ? iconWidth : iconMobileWidth
const mobileIconHeight = (widthOutPut > 720) ? iconHeight : iconMobileHeight

const contentFontSize = (1.5 / 100) * widthOutPut;
const contentMobileFontSize = (3.2 / 100) * widthOutPut;
const mobileContentFontSize = (widthOutPut > 720) ? contentFontSize : contentMobileFontSize;

const contentPFontSize = (1 / 100) * widthOutPut;
const contentPMobileFontSize = (3.2 / 100) * widthOutPut;
const mobilePContentFontSize = (widthOutPut > 720) ? contentPFontSize : contentPMobileFontSize;

const contentimg = (33 / 100) * widthOutPut;
const contentMobileimg = (70 / 100) * widthOutPut;
const mobileContentmg = (widthOutPut > 720) ? contentimg : contentMobileimg;

const donationContentFontSize = (2.3 / 100) * widthOutPut;
const donationContentMobileFontSize = (2.3 / 100) * widthOutPut;
const mobiledonationContentFontSize = (widthOutPut > 720) ? donationContentFontSize : donationContentMobileFontSize;

const donationContentHeight = (50 / 100) * heightOutput;
const donationContentMobileHeight = (25 / 100) * heightOutput;
const mobileDonationContentHeight = (widthOutPut > 720) ? donationContentHeight : donationContentMobileHeight;

const donateBtnFontSize = (1.1 / 100) * widthOutPut;
const donateBtnMobileFontSize = (2.7 / 100) * widthOutPut;
const mobileDonateBtnFontSize = (widthOutPut > 720) ? donateBtnFontSize : donateBtnMobileFontSize;

const doanteBtnHeight = (15 / 100) * donationContentHeight
const doanteBtnWidth = (45 / 100) * donationContentHeight



export const AboutUsFooter = () => {
    return (<>
        <Layout>
            <div className="mainDiv_AboutUsFooter">
                <div className="inner1ManiDiv_AboutUsFooter">
                    <div style={{
                        width: `${mobileHeadingWidth}px`,
                        fontSize: `${mobileHeadingFontSize}px`
                    }} className="InnerHeaderDiv_AboutUsFooter">
                        {/* <img style={{
                            width: `${mobileIconWidth}px`,
                            height: `${mobileIconHeight}px`
                        }} src={CultureIcon} /> */}
                        <h1>
                            About Us
                        </h1>
                        <div style={{
                            width: `${widthUnderLine}px`
                        }} className="underLine_AboutUsFooter"></div>
                    </div>
                </div>
                <div className="inner2MainDiv_AboutUsFooter">
                    <div className="innerInnerUpperDiv_AboutUsFooter">
                        <div className="innerUpperContentDiv_AboutUsFooter">
                            <p style={{
                                fontSize: `${mobilePContentFontSize}px`
                            }}>
                                The Masoom Trust is the CSR wing of MK Agro Tech, the parent company of Karnataka’s favorite brand - Sunpure. The Trust was established in the year 2013 to provide large-scale aid in the fields of Education, Healthcare and Overall development of Mankind. The Trust has relentlessly worked during the times of natural calamities and Covid times, these were unpredicted to the mankind and any empowerment was a boon to the mankind. Trust has also adopted Government schools and took up the mantle of education for underprivileged students. Additionally, they also installed clean drinking water facilities at various locations, in line with their mission to deliver critical amenities to the needy.
                            </p>
                        </div>
                        <div className="innerDownImg_AboutUsFooter">
                            <img style={{
                                width: `${mobileContentmg}px`
                            }} src={Img1} />
                        </div>
                    </div>
                </div>
                <div className="servicesProviderMainDiv_AvoutUsFooter" style={{
                    paddingTop: "6rem"
                }}>
                    <div className="inner1ManiDiv_AboutUsFooter">
                        <div style={{
                            width: `${mobileHeadingWidth}px`,
                            fontSize: `${mobileHeadingFontSize}px`
                        }} className="InnerHeaderDiv_AboutUsFooter">
                            {/* <img style={{
                            width: `${mobileIconWidth}px`,
                            height: `${mobileIconHeight}px`
                        }} src={CultureIcon} /> */}
                            <h1>
                                services we provide
                            </h1>
                            <div style={{
                                width: `${widthUnderLine}px`
                            }} className="underLine_AboutUsFooter"></div>
                        </div>
                    </div>
                    <div className="servicesProviderLowerMainDiv_AboutUsFooter">
                        <div className="servicesProviderLowerUpperMainDIv_AboutUsFooter">
                            <div className="servicesProviderContentMainDiv_AboutUsFooter">
                                <img style={{
                                    width: `${mobileIconWidth}px`,
                                    height: `${mobileIconHeight}px`
                                }} src={CultureDevelopmentIcon} />
                                <h2 style={{
                                    fontSize: `${mobilePContentFontSize}px`
                                }}>Culture development</h2>
                            </div>
                            <div className="servicesProviderContentMainDiv_AboutUsFooter">
                                <img style={{
                                    width: `${mobileIconWidth}px`,
                                    height: `${mobileIconHeight}px`
                                }} src={HumanitarianServicesIcon} />
                                <h2 style={{
                                    fontSize: `${mobilePContentFontSize}px`
                                }}>humanitarian needs</h2>
                            </div>
                            <div className="servicesProviderContentMainDiv_AboutUsFooter">
                                <img style={{
                                    width: `${mobileIconWidth}px`,
                                    height: `${mobileIconHeight}px`
                                }} src={DrinkingServicesIcon} />
                                <h2 style={{
                                    fontSize: `${mobilePContentFontSize}px`
                                }}>drinking water facility</h2>
                            </div>
                        </div>
                        <div className="servicesProviderLowerLowerMainDIv_AboutUsFooter">
                            <div className="servicesProviderContentMainDiv_AboutUsFooter">
                                <img style={{
                                    width: `${mobileIconWidth}px`,
                                    height: `${mobileIconHeight}px`
                                }} src={EductionServicesIcon} />
                                <h2 style={{
                                    fontSize: `${mobilePContentFontSize}px`
                                }}>education</h2>
                            </div>
                            <div className="servicesProviderContentMainDiv_AboutUsFooter">
                                <img style={{
                                    width: `${mobileIconWidth}px`,
                                    height: `${mobileIconHeight}px`
                                }} src={HealthServicesIcon} />
                                <h2 style={{
                                    fontSize: `${mobilePContentFontSize}px`
                                }}>health care</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lowwerMainDiv_AboutUsFooter">
                    <div style={{
                        height: `${mobileDonationContentHeight}px`
                    }} className="LowerinnerMainDiv_AboutUsFooter">
                        <div className="LowerinnerDiv_AboutUsFooter">
                            <h1 style={{
                                fontSize: `${mobiledonationContentFontSize}px`
                            }}>
                                Every dollar you contribute brings us one step closer to our mission's success!
                            </h1>
                            <div style={{
                                width: `${widthUnderLine}px`
                            }} className="underLine_AboutUsFooter"></div>
                        </div>
                        <div className="donateBtn_AboutUsFooter">
                            <button style={{
                                fontSize: `${mobileDonateBtnFontSize}px`,
                                height: `${doanteBtnHeight}px`,
                                width: `${doanteBtnWidth}px`
                            }}>
                                Donate Now
                            </button>
                        </div>
                    </div>
                </div>
                <div className="lowerMainDivStartFundriser_AboutUsFooter">
                    <div className="lowerInnerMainDivStartFundriser_AboutUsFooter">
                        <div className="lowerUpperMainDivStartFundriser_AboutUsFooter">
                            <div className="middleUnderLine_AboutUsFooter"></div>
                            <h2 style={{
                                fontSize: `${mobileHeadingFontSize * 1.5}px`
                            }}>Do You Want To Rise Fund?</h2>
                            <div className="middleUnderLine_AboutUsFooter"></div>
                        </div>
                        <div className="lowerDownMainDivStartFundriser_AboutUsFooter">
                            <button style={{
                                fontSize: `${mobileDonateBtnFontSize}px`,
                                height: `${doanteBtnHeight *0.8}px`,
                                width: `${doanteBtnWidth}px`
                            }}>
                                Start A Fundraiser
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}