import React from "react";
import "../styles/forgotPasswordOTP.css"
import CompanyLogo from "../component/companyLogo/companyLogo";
import EmailLogo from '../svg/email.svg'
import ForgetNumber from '../svg/signupMobile.svg'
import ForgotRightImage from '../svg/forgotRightImage.svg'

export const ForgetPasswordOTP = () => {
    return (
        <>
            <div>
                <div>
                    <CompanyLogo />
                </div>
                <div className="forgotPasswordTopShade_OPT"></div>
                <div className="mainDiv_forgetPassword_OPT">
                    <div className="mainLeftDiv_forgetPassword_OPT">
                        <div className="upperDiv_forgotPassword_OPT">
                            <h1>Forgot Password</h1>
                            <h5>Enter the given below OTP to reset your password</h5>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            
                            <div className="forgetEmail_OPT">
                                <div className="signEmailLogo_forgotPassword_OPT">
                                    <img src={ForgetNumber} className="signEmailLogo_forgotPassword_OPT_logo" />
                                </div>
                                <div className="forgotEmailInput_OPT">
                                    <input type="number" placeholder="- - - - - -" className="input_forgotPassowrd_OPT" />
                                </div>
                            </div>
                        </div>
                        <div className="btnReset_OPT">
                            <button>Get OTP</button>
                        </div>
                        <div className="forgotPasswordbottomShade_OPT" ></div>
                    </div>
                    <div className="mainrightDiv_forgetPassword_OPT">
                        <img src={ForgotRightImage} />
                    </div>
                    <div className="forgotPasswordRightShade_OPT"></div>
                </div>
            </div>
        </>
    )
}