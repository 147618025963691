import React from "react";
import "../styles/activitiesSubHumaintarian.css"
import Layout from "../component/Layout";
import HumanitarianIcon from "../svg/activityHumanitarian.svg";
import Img1 from "../images/humanitarianImg1.png"
import Img2 from "../images/humanitarianImg2.png"
import LowerImg from "../svg/activitiesLowerImg.svg"


const widthOutPut = window.screen.width;
const heightOutput = window.screen.height;
const headingWidth = (33 / 100) * widthOutPut;
const headingMobileWidth = (60 / 100) * widthOutPut;
const mobileHeadingWidth = (widthOutPut > 720) ? headingWidth : headingMobileWidth;

const headingWebFontSize = (1 / 100) * widthOutPut;
const headingMobileFontSize = (2.2 / 100) * widthOutPut;
const mobileHeadingFontSize = (widthOutPut > 720) ? headingWebFontSize : headingMobileFontSize;


const widthUnderLine = (15 / 100) * headingWidth;

const iconWidth = (3.19 / 100) * widthOutPut;
const iconMobileWidth = (8.19 / 100) * widthOutPut;
const iconHeight = (4.23 / 100) * widthOutPut;
const iconMobileHeight = (8.23 / 100) * widthOutPut;

const mobileIconWidth = (widthOutPut > 720) ? iconWidth : iconMobileWidth
const mobileIconHeight = (widthOutPut > 720) ? iconHeight : iconMobileHeight

const contentFontSize = (1.5 / 100) * widthOutPut;
const contentMobileFontSize = (3.2 / 100) * widthOutPut;
const mobileContentFontSize = (widthOutPut > 720) ? contentFontSize : contentMobileFontSize;

const contentPFontSize = (1 / 100) * widthOutPut;
const contentPMobileFontSize = (3.2 / 100) * widthOutPut;
const mobilePContentFontSize = (widthOutPut > 720) ? contentPFontSize : contentPMobileFontSize;

const contentimg = (33 / 100) * widthOutPut;
const contentMobileimg = (70 / 100) * widthOutPut;
const mobileContentmg = (widthOutPut > 720) ? contentimg : contentMobileimg;

const donationContentFontSize = (2.3 / 100) * widthOutPut;
const donationContentMobileFontSize = (2.3 / 100) * widthOutPut;
const mobiledonationContentFontSize = (widthOutPut > 720) ? donationContentFontSize : donationContentMobileFontSize;

const donationContentHeight = (50 / 100) * heightOutput;
const donationContentMobileHeight = (25 / 100) * heightOutput;
const mobileDonationContentHeight = (widthOutPut > 720) ? donationContentHeight : donationContentMobileHeight;

const donateBtnFontSize = (1.1 / 100) * widthOutPut;
const donateBtnMobileFontSize = (2.7 / 100) * widthOutPut;
const mobileDonateBtnFontSize = (widthOutPut > 720) ? donateBtnFontSize : donateBtnMobileFontSize;

const doanteBtnHeight = (15 / 100) * donationContentHeight
const doanteBtnWidth = (45 / 100) * donationContentHeight



export const ActivitiesSubHumanitarian = () => {
    return (<>
        <Layout>
            <div className="mainDiv_ActivitiesHumanitarian">
                <div className="inner1ManiDiv_ActivitiesHumanitarian">
                    <div style={{
                        width: `${mobileHeadingWidth}px`,
                        fontSize: `${mobileHeadingFontSize}px`
                    }} className="InnerHeaderDiv_ActivitiesHumanitarian">
                        <img style={{
                            width: `${mobileIconWidth}px`,
                            height: `${mobileIconHeight}px`
                        }} src={HumanitarianIcon} />
                        <h1>
                        raise funds for humanitarian need of our society
                        </h1>
                        <div style={{
                            width: `${widthUnderLine}px`
                        }} className="underLine_ActivitiesHumanitarian"></div>
                    </div>
                </div>
                <div className="inner2MainDiv_ActivitiesHumanitarian">
                    <div className="innerInnerUpperDiv_ActivitiesHumanitarian">
                        <div className="innerUpperContentDiv_ActivitiesHumanitarian">
                            <h3 style={{
                                fontSize: `${mobileContentFontSize}px`,
                            }}>
                              our aim towards fulfilling humanitarian needs
                            </h3>
                            <p style={{
                                fontSize: `${mobilePContentFontSize}px`
                            }}>
                                With the vision of a better society with no hunger, malnutrition or poverty and to help the underprivileged and raise the bar in the standard of their living. We have worked extensively in distribution of nutrition kits, public infrastructure development, potable water accessibility and nuptial aid.
                            </p>
                        </div>
                        <div className="innerDownImg_ActivitiesHumanitarian">
                            <img style={{
                                width: `${mobileContentmg}px`
                            }} src={Img1} />
                        </div>
                    </div>
                    <div className="innerInnerUpperDiv_ActivitiesHumanitarian innerInner2UpperDiv_ActivitiesHumanitarian">
                        <div className="innerDownImg_ActivitiesHumanitarian">
                            <img style={{
                                width: `${mobileContentmg}px`
                            }} src={Img2} />
                        </div>
                        <div className="innerUpperContentDiv_ActivitiesHumanitarian  innerDownContentDiv_ActivitiesHumanitarian">
                            <h3 style={{
                                fontSize: `${mobileContentFontSize}px`
                            }}>
                               raise fund for the needy one’s
                            </h3>
                            <p style={{
                                fontSize: `${mobilePContentFontSize}px`
                            }}>
                               Your donation supports critical humanitarian efforts. By giving, you provide essential aid to those facing crises, including food, shelter, and medical care. Your generosity makes a tangible difference in the lives of vulnerable individuals and communities worldwide. Please donate today and help us make the world a more compassionate place.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="lowwerMainDiv_ActivitiesHumanitarian">
                    <div style={{
                        height: `${mobileDonationContentHeight}px`
                    }} className="LowerinnerMainDiv_ActivitiesHumanitarian">
                        <div className="LowerinnerDiv_ActivitiesHumanitarian">
                            <h1 style={{
                                fontSize: `${mobiledonationContentFontSize}px`
                            }}>
                                giving is not just aboutmaking a donation, it is all about making a difference
                            </h1>
                            <div style={{
                                width: `${widthUnderLine}px`
                            }} className="underLine_ActivitiesHumanitarian"></div>
                        </div>
                        <div className="donateBtn_ActivitiesHumanitarian">
                            <button style={{
                                fontSize: `${mobileDonateBtnFontSize}px`,
                                height: `${doanteBtnHeight}px`,
                                width: `${doanteBtnWidth}px`
                            }}>
                                Donate Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}