import React from "react";
import "../styles/certificate.css";
import Layout from "../component/Layout";
import CertificateImg1 from "../images/certificateImg1.jpg"

const widthOutput = window.screen.width;
const heightOutput = window.screen.height
const headerFontSize = (4 / 100) * widthOutput;

export const Certificate = () => {
    return (<>
        <Layout>
            <div className="mainPAge_Certificate">
                <div className="headerMAinDIv_Certificate">
                    <div className="headingDiv_Certificate">
                        <div style={{
                            display: "flex"
                        }}>
                            <div style={{
                                display:"flex",
                                flexDirection:"column",
                                alignItems:"center"
                            }}>
                                <h1 style={{
                                    fontSize: `${headerFontSize}px`
                                }}>
                                    Certificate
                                </h1>
                                <div style={{
                                    // width: `${widthUnderLine}px`
                                }} className="underLine_Certificate"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="MAinDiv_Certificate">
                    <div className="MainDiv_Certificate">
                        <div className="InnerMAinDiv_Certificate">
                            <div className="Header_Certificate">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.4}px`
                                }}>Certificate received from Ministry of Health
                                and Family Welfare, Government of India</h2>
                            </div>
                            {/* <div className="underLineHeadingMainDiv_Certificate">
                                <div className="underLineHeading_Certificate"></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="ContentMainDiv_Certificate">
                    <div className="ContentInnerMainDiv_Certificate">
                        <div className="contentImgMainDiv_Certificate">
                            <img src={CertificateImg1} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}