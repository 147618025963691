import Layout from "../component/Layout";
import HomePage from "../component/homepage/homepage";
import ManageFund from "../component/manageFund/managefund";
import OurGallery from "../component/ourGallery/ourGallery";
import RiseFund from "../component/risefund/riseFund";
import SimpleStep from "../container/simpleStep";
import SuccessStories from "../component/successStories/sucessStories";
import WhyMasoom from "../component/whyMasoom/whyMasoom";
import { AboutUs } from "./aboutUs";

export const Dashboard = () => {
    return <Layout>
        <HomePage />
        <SimpleStep />
        <ManageFund />
        <OurGallery />
        <RiseFund />
        <WhyMasoom />
        <SuccessStories />
    </Layout>
}
