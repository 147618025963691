import { Routes, Route } from "react-router-dom"
import { pagePaths } from "./utils/constants"
import { Categories, Dashboard, ForgetPassword, HowItWork, Login, SignUp } from "./container";
import { ForgetPasswordOTP } from "./container/forgotPasswordOTP";
import { AboutUs } from "./container/aboutUs";
import { CategoriesForDropDown } from "./container/browserfundrasierdropdown";
import { SuccessStoriesFooter } from "./container/sucessStoriesFooter";
import { AboutUsFooter } from "./container/aboutusFooter";
import { InTheNews } from "./container/intheNews";
import { FundraiserTips } from "./container/fundraiserTips";
import { SponsorChild } from "./container/sponsorChild";
import { WhatIsCrowdFundig } from "./container/whatisCrowdingFunding";
import { WithDrawFund } from "./container/withdrawFund";
import { MedicalSupport } from "./container/medicalSupport";
import { ActivitiesSubEduction } from "./container/activitiesSubEduction";
import { ActivitiesSubHealth } from "./container/activitiesSubHelth";
import { ActivitiesSubCulture } from "./container/activitiesSubCulture";
import { ActivitiesSubHumanitarian } from "./container/activitiesSubHumaintarian";
import { GalleriesDetailPage } from "./container/ourGalliersDelaitsPage";
import { Agenda } from "./container/agenda";
import { MasoomTrustActivities } from "./container/masoomTrustActivities";
import { OtherActivities } from "./container/otherActivities";
import { WomenEmpowerment } from "./container/womenEmpowerment";
import { Tuberculosis } from "./container/tuberculosis";
import { Certificate } from "./container/certificate";
import { NewInitiative } from "./container/newInitiative";

const AppRouter = () => {
    return <Routes>
        <Route path={pagePaths.root} element={<Dashboard />} />
        <Route path={pagePaths.login} element={<Login />} />
        <Route path={pagePaths.signup} element={<SignUp />} />
        <Route path={pagePaths.browserfundrasier} element={<Categories />} />
        <Route path={pagePaths.browserfundrasierdropdown} element={<CategoriesForDropDown />} />
        <Route path={pagePaths.howItWork} element={<HowItWork />} />
        <Route path={pagePaths.forgetPassword} element={<ForgetPassword />} />
        <Route path={pagePaths.forgotPasswordOTP} element={<ForgetPasswordOTP />} />
        <Route path={pagePaths.aboutuspage} element={<AboutUs />} />
        <Route path={pagePaths.sucessStoriesFooter} element={<SuccessStoriesFooter />} />
        <Route path={pagePaths.aboutusFooterMasoomTrust} element={<AboutUsFooter />} />
        <Route path={pagePaths.intheNewsFooter} element={<InTheNews />} />
        <Route path={pagePaths.fundraiserTips} element={<FundraiserTips />} />
        <Route path={pagePaths.sponsorChild} element={<SponsorChild />} />
        <Route path={pagePaths.whatiscrowfunding} element={<WhatIsCrowdFundig />} />
        <Route path={pagePaths.withdrawFund} element={<WithDrawFund />} />
        <Route path={pagePaths.medicalSupport} element={<MedicalSupport />} />
        <Route path={pagePaths.activitiesSubEduction} element={<ActivitiesSubEduction />} />
        <Route path={pagePaths.activitiesSubHealth} element={<ActivitiesSubHealth />} />
        <Route path={pagePaths.activitiesSubCulture} element={<ActivitiesSubCulture />} />
        <Route path={pagePaths.activitiesSubHumanitarian} element={<ActivitiesSubHumanitarian />} />
        <Route path={pagePaths.galleriesDetailsPage} element={<GalleriesDetailPage />} />
        <Route path={pagePaths.agenda} element={<Agenda />} />
        <Route path={pagePaths.masoomTrustActivities} element={<MasoomTrustActivities />} />
        <Route path={pagePaths.otherActivities} element={<OtherActivities />} />
        <Route path={pagePaths.womenEmpowerment} element={<WomenEmpowerment />} />
        <Route path={pagePaths.tuberculsios} element={<Tuberculosis />} />
        <Route path={pagePaths.certificate} element={<Certificate />} />
        <Route path={pagePaths.newInitiative} element={<NewInitiative />} />


    </Routes>
}

export default AppRouter;