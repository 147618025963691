import React from "react";
import "../styles/tuberculosis.css";
import Layout from "../component/Layout";
import TuberculsiosImg1 from "../images/tuberclusiosImg1.png"

const widthOutput = window.screen.width;
const heightOutput = window.screen.height
const headerFontSize = (4 / 100) * widthOutput;

export const Tuberculosis = () => {
    return (<>
        <Layout>
            <div className="mainPAge_Tuberculosis">
                <div className="headerMAinDIv_Tuberculosis">
                    <div className="headingDiv_Tuberculosis">
                        <div style={{
                            display: "flex"
                        }}>
                            {/* <h1 style={{
                                fontSize: `${headerFontSize}px`,
                                textTransform: "capitalize"
                            }}>women&nbsp; </h1> */}
                            <div style={{
                                display:"flex",
                                flexDirection:"column",
                                alignItems:"center"
                            }}>
                                <h1 style={{
                                    fontSize: `${headerFontSize}px`,
                                    textTransform: "capitalize"
                                }}>
                                    tuberculosis (TB)
                                </h1>
                                <div style={{
                                    // width: `${widthUnderLine}px`
                                }} className="underLine_Tuberculosis"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ContentMainDiv_Tuberculosis">
                    <div className="ContentInnerMainDiv_Tuberculosis">
                        <div className="contentImgMainDiv_Tuberculosis">
                            <img src={TuberculsiosImg1} />
                        </div>
                        <div className="contentDiv_Tuberculosis">
                            <h1 style={{
                                fontSize: `${headerFontSize * 0.4}px`
                            }}>tB free india campaign
                            </h1>
                            <h2 style={{
                                fontSize: `${headerFontSize * 0.30}px`,
                                paddingBottom:"0.5rem"
                            }}>M.K. AgroTech received a request with regards to the<br></br> Honorable Prime Minister's "TB Free India Campaign". The TB <br></br>Patients details were shared through the government<br></br> hospital of Srirangapatna.
                            </h2>
                            <h2 style={{
                                fontSize: `${headerFontSize * 0.30}px`,
                                paddingBottom:"0.5rem"
                            }}>Grocery Kits will be distributed to the TB Patients in the<br></br> Govt. Hospital for 6 months.
                            </h2>
                            <h2 style={{
                                fontSize: `${headerFontSize * 0.30}px`
                            }}>The products include are as per govt guidelines.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}