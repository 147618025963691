import React from "react";
import "../styles/masoomTrustActivities.css";
import Layout from "../component/Layout";
import LoactionIcon from "../svg/locationActivitiesIcon.svg";
import MaharajaImg from "../images/mysoreMaharajaImg.jpg";
import mysoreImg1 from "../images/mysoreActivitiesImg1.png"
import mysoreImg2 from "../images/mysoreActivitiesImg2.png";
import UydagiriImg1 from "../images/uydagiriImg1.png";
import UydagiriImg2 from "../images/uydagiriImg2.png";
import UydagiriImg3 from "../images/uydagiriImg3.png";
import SrirangapatanaImg1 from "../images/SrirangapatanaImg1.png";
import SrirangapatanaImg2 from "../images/SrirangapatanaImg2.png";
import SrirangapatanaImg3 from "../images/SrirangapatanaImg3.png";
import TumkurImg1 from "../images/tumkurImg1.png";
import TumkurImg2 from "../images/tumkurImg2.png";
import TumkurImg3 from "../images/tumkurImg3.png";
import TumkurImg4 from "../images/tumkurImg4.png";
import TumkurImg5 from "../images/tumkurImg5.png";
import TumkurImg6 from "../images/tumkurImg6.png";

import ShivamoggaImg1 from "../images/ShivamoggaImg1.png";
import ShivamoggaImg2 from "../images/ShivamoggaImg2.png";
import ShivamoggaImg3 from "../images/ShivamoggaImg3.png";
import ShivamoggaImg4 from "../images/ShivamoggaImg4.png";
import ShivamoggaImg5 from "../images/ShivamoggaImg5.png";
import ShivamoggaImg6 from "../images/ShivamoggaImg6.png";


const widthOutput = window.screen.width;
const heightOutput = window.screen.height
const headerFontSize = (4 / 100) * widthOutput;
const headerContentFontSize = (1.3 / 100) * widthOutput;
const focusContentFontSize = (1.3 / 100) * widthOutput;
const donationContentHeight = (50 / 100) * heightOutput;

const headingWebFontSize = (1 / 100) * widthOutput;
const headingMobileFontSize = (2.2 / 100) * widthOutput;
const mobileHeadingFontSize = (widthOutput > 720) ? headingWebFontSize : headingMobileFontSize;

const headingWidth = (33 / 100) * widthOutput;
const headingMobileWidth = (60 / 100) * widthOutput;
const mobileHeadingWidth = (widthOutput > 720) ? headingWidth : headingMobileWidth;
const widthUnderLine = (15 / 100) * headingWidth;


const donationContentFontSize = (2.3 / 100) * widthOutput;
const donationContentMobileFontSize = (2.3 / 100) * widthOutput;
const mobiledonationContentFontSize = (widthOutput > 720) ? donationContentFontSize : donationContentMobileFontSize;
const donationContentMobileHeight = (25 / 100) * heightOutput;
const mobileDonationContentHeight = (widthOutput > 720) ? donationContentHeight : donationContentMobileHeight;

const donateBtnFontSize = (1.1 / 100) * widthOutput;
const donateBtnMobileFontSize = (2.7 / 100) * widthOutput;
const mobileDonateBtnFontSize = (widthOutput > 720) ? donateBtnFontSize : donateBtnMobileFontSize;

const doanteBtnHeight = (15 / 100) * donationContentHeight
const doanteBtnWidth = (45 / 100) * donationContentHeight

const focusEngagement = [
    {
        content: "Medical Aid Support for Chemo-therapy Care for Cancer Patients.",
        number: "01"
    },
    {
        content: "Medical Aid Support for Dialysis patients on-going basis.",
        number: "02"
    },
    {
        content: "Setting up of Toilets in Government Schools & hospitals.",
        number: "03"
    },
    {
        content: "Promoting Rural Education and Allied supports.",
        number: "04"
    },
    {
        content: "Promoting Rural Education and Allied supports.",
        number: "05"
    },
    {
        content: "Conducting Free Health Check-Up Camps.",
        number: "06"
    },
    {
        content: "Medical Aid Support for Major Surgeries.",
        number: "07"
    },
    {
        content: "Women empowerment center in Mysore.",
        number: "08"
    },
    {
        content: "Adoption of Government Schools..",
        number: "09"
    },
    {
        content: "Note book distribution in Government Schools",
        number: "10"
    },
]

export const MasoomTrustActivities = () => {
    return (<>
        <Layout>
            <div className="mainPAge_MasoomTrustActivities">
                <div className="headerMAinDIv_MasoomTrustActivities">
                    <div className="headingDiv_MasoomTrustActivities">
                        <div style={{
                            display: "flex"
                        }}>
                            <h1 style={{
                                fontSize: `${headerFontSize}px`
                            }}>Masoom&nbsp; </h1>
                            <div>
                                <h1 style={{
                                    fontSize: `${headerFontSize}px`
                                }}>
                                    Trust
                                </h1>
                                <div style={{
                                    // width: `${widthUnderLine}px`
                                }} className="underLine_MasoomTrustActivities"></div>
                            </div>
                            <h1 style={{
                                fontSize: `${headerFontSize}px`
                            }}> &nbsp;Activities </h1>
                        </div>
                    </div>
                </div>
                <div className="MainmysoreOneMAinDiv_MasoomTrustActivities">
                    <div className="mysoreOneMAinDiv_MasoomTrustActivities">
                        <div className="mysoreOneInnerMAinDiv_MasoomTrustActivities">
                            <div className="mysoreOneHeader_MasoomTrustActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.5}px`
                                }}>glimpse of medical camp</h2>
                            </div>
                            <div className="underLineMysorOneHeadingMainDiv_MasoomTruestActivities">
                                <div className="underLineMysorOneHeading_MasoomTruestActivities"></div>
                            </div>
                        </div>
                        <div className="mysorOneLocationMainDiv_MasoomTrustActivities">
                            <div className="mysoreOneMoctionInnerDiv_MasoomTrustActivities">
                                <img src={LoactionIcon} />
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.4}px`
                                }}>&nbsp;MYSORE</h2>
                            </div>
                        </div>
                        <div className="maysoreOneImgMainDiv_MasoomTrustActivities">
                            <div> <img src={MaharajaImg} /></div>
                            <div className="mysoreOneImgeContent_MasoomTrustActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.3}px`
                                }}>A proud moment for us to have Maharaja ji, Maharani Ji & Our own Salumarada Thimmaka ji for Health Camp.</h2>
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.3}px`
                                }}>A Day to cherish for a lifetime in the Books of Masoom Trust.</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="MainmysoreTwoMAinDiv_MasoomTrustActivities">
                    <div className="mysoreTwoMAinDiv_MasoomTrustActivities">
                        <div className="mysoreTwoInnerMAinDiv_MasoomTrustActivities">
                            <div className="mysoreTwoHeader_MasoomTrustActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.5}px`
                                }}>glimpse of grand launch of women empowerment center</h2>
                            </div>
                            <div className="underLineMysorTwoHeadingMainDiv_MasoomTruestActivities">
                                <div className="underLineMysorTwoHeading_MasoomTruestActivities"></div>
                            </div>
                        </div>
                        <div className="mysorTwoLocationMainDiv_MasoomTrustActivities">
                            <div className="mysoreTwoMoctionInnerDiv_MasoomTrustActivities">
                                <img src={LoactionIcon} />
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.4}px`
                                }}>&nbsp;MYSORE</h2>
                            </div>
                        </div>
                        <div className="maysoreTwoImgMainDiv_MasoomTrustActivities">
                            <div className="imgMainDiv_MasoomTrustActivities">
                                <img style={{
                                    width: "28%"
                                }} src={mysoreImg1} />
                                <img style={{
                                    width: "70%"
                                }} src={mysoreImg2} />
                            </div>
                            <div className="mysoreTwoImgeContent_MasoomTrustActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.3}px`
                                }}>Beneficiaries Empowered / Our Token of Empowerment to the Beneficiaries</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="MainUdyagiriMAinDiv_MasoomTrustActivities">
                    <div className="UdyagiriMAinDiv_MasoomTrustActivities">
                        <div className="UdyagiriInnerMAinDiv_MasoomTrustActivities">
                            <div className="UdyagiriHeader_MasoomTrustActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.5}px`
                                }}>glimpse of women empowerment center</h2>
                            </div>
                            <div className="underLineUdyagiriHeadingMainDiv_MasoomTruestActivities">
                                <div className="underLineUdyagiriHeading_MasoomTruestActivities"></div>
                            </div>
                        </div>
                        <div className="UdyagiriLocationMainDiv_MasoomTrustActivities">
                            <div className="UdyagiriMoctionInnerDiv_MasoomTrustActivities">
                                <img src={LoactionIcon} />
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.4}px`
                                }}>&nbsp;udaygiri (mysore)</h2>
                            </div>
                        </div>
                        <div className="UdyagiriImgMainDiv_MasoomTrustActivities">
                            <div className="UdyagiriimgMainDiv_MasoomTrustActivities">
                                <img src={UydagiriImg1} />
                                <img src={UydagiriImg2} />
                                <img src={UydagiriImg3} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="MainSrirangapatanaMAinDiv_MasoomTrustActivities">
                    <div className="SrirangapatanaMAinDiv_MasoomTrustActivities">
                        <div className="SrirangapatanaInnerMAinDiv_MasoomTrustActivities">
                            <div className="SrirangapatanaHeader_MasoomTrustActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.5}px`,
                                    textAlign: "center"
                                }}>A Glimpse of the First Event conducted on 17th Nov 2022 at<br></br>
                                    Government Hospital</h2>
                            </div>
                            <div className="underLineSrirangapatanaHeadingMainDiv_MasoomTruestActivities">
                                <div className="underLineSrirangapatanaHeading_MasoomTruestActivities"></div>
                            </div>
                        </div>
                        <div className="SrirangapatanaLocationMainDiv_MasoomTrustActivities">
                            <div className="SrirangapatanaMoctionInnerDiv_MasoomTrustActivities">
                                <img src={LoactionIcon} />
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.4}px`
                                }}>&nbsp;Srirangapatana</h2>
                            </div>
                        </div>
                        <div className="SrirangapatanaImgMainDiv_MasoomTrustActivities">
                            <div className="SrirangapatanaimgMainDiv_MasoomTrustActivities">
                                <img src={SrirangapatanaImg1} />
                                <img src={SrirangapatanaImg2} />
                                <img src={SrirangapatanaImg3} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="MaintumkurMAinDiv_MasoomTrustActivities">
                    <div className="tumkurMAinDiv_MasoomTrustActivities">
                        <div className="tumkurInnerMAinDiv_MasoomTrustActivities">
                            <div className="tumkurHeader_MasoomTrustActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.5}px`
                                }}>glimpse of medical camp at siddaganga hospital</h2>
                            </div>
                            <div className="underLinetumkurHeadingMainDiv_MasoomTruestActivities">
                                <div className="underLinetumkurHeading_MasoomTruestActivities"></div>
                            </div>
                        </div>
                        <div className="tumkurLocationMainDiv_MasoomTrustActivities">
                            <div className="tumkurMoctionInnerDiv_MasoomTrustActivities">
                                <img src={LoactionIcon} />
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.4}px`
                                }}>&nbsp;tumkur</h2>
                            </div>
                        </div>
                        <div className="tumkurImgMainDiv_MasoomTrustActivities">
                            <div className="tumkurimgMainDiv_MasoomTrustActivities">
                                <img src={TumkurImg1} />
                                <img src={TumkurImg2} />
                                <img src={TumkurImg3} />
                                <img src={TumkurImg4} />
                                <img src={TumkurImg5} />
                                <img src={TumkurImg6} />
                            </div>
                        </div>
                        <div className="mysoreTwoImgeContent_MasoomTrustActivities">
                            <h2 style={{
                                fontSize: `${headerFontSize * 0.3}px`
                            }}>Cancer Patient Empowered / Our Token of Empowerment to the Larger Organisation – Siddaganga Hospital</h2>
                        </div>
                    </div>
                </div>
                <div className="MainShivamoggaMAinDiv_MasoomTrustActivities">
                    <div className="ShivamoggaMAinDiv_MasoomTrustActivities">
                        <div className="ShivamoggaInnerMAinDiv_MasoomTrustActivities">
                            <div className="ShivamoggaHeader_MasoomTrustActivities">
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.5}px`,
                                    textAlign: "center"
                                }}>Glimpse of Post Medical Camp at MAX Hospital</h2>
                            </div>
                            <div className="underLineShivamoggaHeadingMainDiv_MasoomTruestActivities">
                                <div className="underLineShivamoggaHeading_MasoomTruestActivities"></div>
                            </div>
                        </div>
                        <div className="ShivamoggaLocationMainDiv_MasoomTrustActivities">
                            <div className="ShivamoggaMoctionInnerDiv_MasoomTrustActivities">
                                <img src={LoactionIcon} />
                                <h2 style={{
                                    fontSize: `${headerFontSize * 0.4}px`
                                }}>&nbsp;Shivamogga</h2>
                            </div>
                        </div>
                        <div className="ShivamoggaImgMainDiv_MasoomTrustActivities">
                            <div className="ShivamoggaimgMainDiv_MasoomTrustActivities">
                                <img src={ShivamoggaImg1} />
                                <img src={ShivamoggaImg2} />
                                <img src={ShivamoggaImg3} />

                            </div>
                            <div className="ShivamoggaimgLowerMainDiv_MasoomTrustActivities">
                                <img src={ShivamoggaImg4} />
                                <div className="ShivamoggaimgLowerInnerMainDiv_MasoomTrustActivities">
                                    <img src={ShivamoggaImg5} />
                                    <img src={ShivamoggaImg6} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}

