import React from "react";
import ComingSoon from "../images/comingsoon.avif"
import Layout from "../component/Layout";

export const InTheNews = () => {
    return (<>
        <Layout>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <img src={ComingSoon} />
            </div>
        </Layout>
    </>)
}