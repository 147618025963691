import React, { useCallback, useEffect, useMemo, useState } from 'react'
import '../../styles/toolbaar.css'
import { AiFillCaretDown } from 'react-icons/ai'
import { CiSearch } from 'react-icons/ci'
import { GiHamburgerMenu } from 'react-icons/gi'
import Logo from '../../svg/logo.svg';
import { pagePaths } from '../../utils/constants';
import axios from 'axios'
import { useDebounce } from 'use-debounce'


const waitTime = 300;

const ToolBar = () => {
    const [dataCategories, setDataCategories] = useState([]);
    const [showHamburger, setShowHambuger] = useState(false)
    const [searchData, setSearchData] = useState("")
    const [categories, setCategories] = useState([])
    const [filteredCategories, setFilteredCategories] = useState([])
    const [debouncedText] = useDebounce(searchData, waitTime);




    let searchrecordData = ""

    const fetchData_Categories = async () => {
        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/category/all`;

            const res = await axios.get(url)
            const { data } = res;
            setDataCategories(data)
        } catch (error) {

        }
    }
    const fetchData_Search = async () => {
        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/campaign/search?text=health`;

            const res = await axios.get(url)
            const { data } = res;
            setCategories(data)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData_Categories();
        fetchData_Search();

    }, [])

    const filterCategories = useCallback(() => {
        if (debouncedText !== "") {
            const newCategoriesList = categories.filter((item) => {
                if (item.title && item.title.toString().toLowerCase().includes(debouncedText.toLowerCase())) {
                    return true
                }
                return false;
            });

            setFilteredCategories(newCategoriesList)
        }
        else {
            setFilteredCategories([])
        }
    }, [debouncedText])

    useEffect(() => {
        filterCategories()
    }, [filterCategories])

    const handleChange = (e) => {
        setSearchData(e.target.value)
    }

    return (
        <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className='mainToolbarDiv'>
                    <div className='leftCornerShade'></div>
                    <div className='toolbarLogo'>
                        <a href={pagePaths.root}><img src={Logo} /></a>
                    </div>
                    <div className="mobileBarMenuSearchItem__ToolBar" style={{ display: "flex", flexDirection: "column" }}>

                        <div className='mobileToolBarMenu_toolbarMenuSearchIcon toolbarMenuSearchIcon'>
                            <input
                                className='mobileToolBarMenu_searchInput searchInput'
                                type="search"
                                placeholder="Search Here"
                                value={searchData}
                                // onChange={debounceSearch}
                                onChange={handleChange}
                            >
                            </input>
                            <div className='mobileToolBarMenu_searchIcon searchIcon'><CiSearch /></div>
                        </div>
                        <div className='searchItem'>
                            {
                                debouncedText !== "" ? (filteredCategories.length > 0 ?
                                    <div className='searchItemInnerDiv'>
                                        {
                                            filteredCategories.map((item, i) => {
                                                return <div key={item.id} className='searchItemInnerMain_Toolbar'>
                                                    <a className='searchItemAtag' target='_blank' key={i} href={`aboutuspage?id=${item.id}`}><h5>{item.title}</h5></a>
                                                </div>
                                            })
                                        }
                                    </div>
                                    : <div className='searchNotFound_ToolBar'>
                                        <h6>No Categories Found!</h6>
                                    </div>) : null
                            }
                        </div>
                    </div>
                    <div className={showHamburger ? "mobileToolBarMenu" : 'toolbarMenu'} style={{ width: "90%" }}  >

                        <div className='mobileToolBarMenuMian' style={{ width: "70%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <a href={pagePaths.browserfundrasier} className=' mobileToolBarMenu_toolbarNenu1 toolbarNenu1' >
                                Browser Fundraisers
                            </a>

                            <div className='mobileToolBarMenu_dropdownMenuSub dropdownMenuSub'>
                                <div className="mobileToolBarMenu_dropdown dropdown">
                                    <button className="mobileToolBarMenu_dropbtn dropbtn">Fundraiser For&nbsp;&nbsp; <AiFillCaretDown /></button>
                                    <div className="mobileToolBarMenu_dropdown-content dropdown-content">

                                        {
                                            dataCategories?.map((item) => {
                                                console.log("checking in data categrios", item.id)
                                                return <a href={`browserfundrasierdropdown?id=${item.id}`}><div style={{ display: "flex", justifyContent: "space-between" }}>{item.name} <img style={{width:"10%"}} src={item.icon} /></div></a>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <a href={pagePaths.howItWork} className='mobileToolBarMenu_howitwork howitwork'>
                                How it Works
                            </a>
                            <div className='mobileToolBarMenu_menuDivder menuDivder'>
                                |
                            </div>
                            {
                                showHamburger ? null :
                                    <div className='mobileToolBarMenu_toolbarMenuSearch toolbarMenuSearch' style={{ display: "flex", flexDirection: "column" }}>

                                        <div className='mobileToolBarMenu_toolbarMenuSearchIcon toolbarMenuSearchIcon'>
                                            <input
                                                className='mobileToolBarMenu_searchInput searchInput'
                                                type="search"
                                                placeholder="Search Here"
                                                value={searchData}
                                                onChange={handleChange}
                                            >
                                            </input>
                                            <div className='mobileToolBarMenu_searchIcon searchIcon'><CiSearch /></div>
                                        </div>
                                        <div className='searchItem'>
                                            {
                                                debouncedText !== "" ? (filteredCategories.length > 0 ?
                                                    <div className='searchItemInnerDiv'>
                                                        {
                                                            filteredCategories.map((item, i) => {
                                                                searchrecordData = item.title
                                                                return <div key={item.id} className='searchItemInnerMain_Toolbar'>
                                                                    <a className='searchItemAtag' key={i} href={`aboutuspage?id=${item.id}`}><h5>{item.title}</h5></a>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    : <div className='searchNotFound_ToolBar'>
                                                        <h6>No Categories Found!</h6>
                                                    </div>) : null
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className='mobileToolBarMenu_toolbarButton toolbarButton' style={{ width: "30%" }}>
                            <a href={pagePaths.signup} className='mobileToolBarMenu_toolbarButton1 toolbarButton1'>
                                Start a Fundraisers
                            </a>
                            <a href={pagePaths.login} className='mobileToolBarMenu_toolbarButton2 toolbarButton2'>
                                Sign In
                            </a>
                        </div>
                    </div>
                </div>
                <div className='hambugerMenu' onClick={() => setShowHambuger(!showHamburger)}>
                    <GiHamburgerMenu />
                </div>
            </div>
        </>
    )
}
export default ToolBar;