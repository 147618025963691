import React from "react";
import '../styles/progressBar.css'
import ProgressBar from "@ramonak/react-progress-bar";

export const ProgressBar1 = (props) => {
    const { progressBarPercent } = props
    console.log("inside progress", progressBarPercent)
    const value = 60;
    const integerValue = Math.round(progressBarPercent)
    const graterThanZero = ()=>{
        if(integerValue >0){
            return integerValue +`%`
        }else{
            return ""
        }
    }
    return (
        <div className="container">
            {/* <div className="progress-container">
                <div className="indicator" style={{ width: `${progressBarPercent}%` }}>
                </div>
                <ProgressBar 
                className="orange-progress-bar" 
                completed = {progressBarPercent} 
                animateOnRender={true} 
                bgColor="#FFAF65"
                transitionDuration="1.5s"
                transitionTimingFunction="ease-in-out"
                initCompletedOnAnimation={1}
                />
            </div> */}
            <div className="progress-check">
                <div
                    className="fill a"
                    style={{ width: `${progressBarPercent}%`, textAlign: "center", color: "black", display: "flex", alignItems: "center", justifyContent: "end" }}>
                    {`${graterThanZero()}`}
                </div>
            </div>
        </div>
    );
};
