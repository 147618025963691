import React from "react";
import "../styles/activitiesSubCulture.css"
import Layout from "../component/Layout";
import CultureIcon from "../svg/activityCulture.svg";
import Img1 from "../images/cultureImg1.png"
import Img2 from "../images/cultureImg2.png"
import LowerImg from "../svg/activitiesLowerImg.svg"


const widthOutPut = window.screen.width;
const heightOutput = window.screen.height;
const headingWidth = (33 / 100) * widthOutPut;
const headingMobileWidth = (60 / 100) * widthOutPut;
const mobileHeadingWidth = (widthOutPut > 720) ? headingWidth : headingMobileWidth;

const headingWebFontSize = (1 / 100) * widthOutPut;
const headingMobileFontSize = (2.2 / 100) * widthOutPut;
const mobileHeadingFontSize = (widthOutPut > 720) ? headingWebFontSize : headingMobileFontSize;


const widthUnderLine = (15 / 100) * headingWidth;

const iconWidth = (3.19 / 100) * widthOutPut;
const iconMobileWidth = (8.19 / 100) * widthOutPut;
const iconHeight = (4.23 / 100) * widthOutPut;
const iconMobileHeight = (8.23 / 100) * widthOutPut;

const mobileIconWidth = (widthOutPut > 720) ? iconWidth : iconMobileWidth
const mobileIconHeight = (widthOutPut > 720) ? iconHeight : iconMobileHeight

const contentFontSize = (1.5 / 100) * widthOutPut;
const contentMobileFontSize = (3.2 / 100) * widthOutPut;
const mobileContentFontSize = (widthOutPut > 720) ? contentFontSize : contentMobileFontSize;

const contentPFontSize = (1 / 100) * widthOutPut;
const contentPMobileFontSize = (3.2 / 100) * widthOutPut;
const mobilePContentFontSize = (widthOutPut > 720) ? contentPFontSize : contentPMobileFontSize;

const contentimg = (33 / 100) * widthOutPut;
const contentMobileimg = (70 / 100) * widthOutPut;
const mobileContentmg = (widthOutPut > 720) ? contentimg : contentMobileimg;

const donationContentFontSize = (2.3 / 100) * widthOutPut;
const donationContentMobileFontSize = (2.3 / 100) * widthOutPut;
const mobiledonationContentFontSize = (widthOutPut > 720) ? donationContentFontSize : donationContentMobileFontSize;

const donationContentHeight = (50 / 100) * heightOutput;
const donationContentMobileHeight = (25 / 100) * heightOutput;
const mobileDonationContentHeight = (widthOutPut > 720) ? donationContentHeight : donationContentMobileHeight;

const donateBtnFontSize = (1.1 / 100) * widthOutPut;
const donateBtnMobileFontSize = (2.7 / 100) * widthOutPut;
const mobileDonateBtnFontSize = (widthOutPut > 720) ? donateBtnFontSize : donateBtnMobileFontSize;

const doanteBtnHeight = (15 / 100) * donationContentHeight
const doanteBtnWidth = (45 / 100) * donationContentHeight



export const ActivitiesSubCulture = () => {
    return (<>
        <Layout>
            <div className="mainDiv_ActivitiesCulture">
                <div className="inner1ManiDiv_ActivitiesCulture">
                    <div style={{
                        width: `${mobileHeadingWidth}px`,
                        fontSize: `${mobileHeadingFontSize}px`
                    }} className="InnerHeaderDiv_ActivitiesCulture">
                        <img style={{
                            width: `${mobileIconWidth}px`,
                            height: `${mobileIconHeight}px`
                        }} src={CultureIcon} />
                        <h1>
                        raise funds for the cultural development of society
                        </h1>
                        <div style={{
                            width: `${widthUnderLine}px`
                        }} className="underLine_ActivitiesCulture"></div>
                    </div>
                </div>
                <div className="inner2MainDiv_ActivitiesCulture">
                    <div className="innerInnerUpperDiv_ActivitiesCulture">
                        <div className="innerUpperContentDiv_ActivitiesCulture">
                            <h3 style={{
                                fontSize: `${mobileContentFontSize}px`,
                            }}>
                               our aim towards our cultural development
                            </h3>
                            <p style={{
                                fontSize: `${mobilePContentFontSize}px`
                            }}>
                                A Healthy society means a happy society. Being one of our major focus engagement areas, our projects include access to better healthcare facilities/infrastructure, medicine & improved sanitation. This includes dialysis center formation, health camps, economic reach, oncology support and periodical monitory aid.
                            </p>
                        </div>
                        <div className="innerDownImg_ActivitiesCulture">
                            <img style={{
                                width: `${mobileContentmg}px`
                            }} src={Img1} />
                        </div>
                    </div>
                    <div className="innerInnerUpperDiv_ActivitiesCulture innerInner2UpperDiv_ActivitiesCulture">
                        <div className="innerDownImg_ActivitiesCulture">
                            <img style={{
                                width: `${mobileContentmg}px`
                            }} src={Img2} />
                        </div>
                        <div className="innerUpperContentDiv_ActivitiesCulture  innerDownContentDiv_ActivitiesCulture">
                            <h3 style={{
                                fontSize: `${mobileContentFontSize}px`
                            }}>
                                raise fund for the needy one’s
                            </h3>
                            <p style={{
                                fontSize: `${mobilePContentFontSize}px`
                            }}>
                                Your donation can ignite cultural growth among underserved communities. By contributing, you empower individuals to celebrate their heritage, preserve traditions, and foster creativity. Help us ensure that cultural development reaches those who need it most, creating a more enriched and diverse society. Donate today to make a lasting impact on cultural vibrancy.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="lowwerMainDiv_ActivitiesCulture">
                    <div style={{
                        height: `${mobileDonationContentHeight}px`
                    }} className="LowerinnerMainDiv_ActivitiesCulture">
                        <div className="LowerinnerDiv_ActivitiesCulture">
                            <h1 style={{
                                fontSize: `${mobiledonationContentFontSize}px`
                            }}>
                                giving is not just aboutmaking a donation, it is all about making a difference
                            </h1>
                            <div style={{
                                width: `${widthUnderLine}px`
                            }} className="underLine_ActivitiesCulture"></div>
                        </div>
                        <div className="donateBtn_ActivitiesCulture">
                            <button style={{
                                fontSize: `${mobileDonateBtnFontSize}px`,
                                height: `${doanteBtnHeight}px`,
                                width: `${doanteBtnWidth}px`
                            }}>
                                Donate Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}