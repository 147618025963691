import Footer from "../container/footer";
import ToolBar from "./toolbar/toolbar";

const Layout = ({ children }) => {
    return (<>
        <ToolBar />
        {children}
        <Footer />
    </>);
}

export default Layout