import React, { useState, useEffect, useRef } from "react";
import "../styles/fundraiserTips.css";
import Layout from "../component/Layout";
import HeaderImg from "../images/fundraiserTipHeaderImg.jpg"
import { BsArrowRightCircleFill } from "react-icons/bs"
import { pagePaths } from "../utils/constants";

export const FundraiserTips = () => {
    const [activeSection, setActiveSection] = useState(null);
    const sections = useRef([]);

    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;


        sections.current.forEach((section) => {
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;
            console.log("checking y", pageYOffset, (section.offsetTop), sectionHeight, section)

            if (pageYOffset >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
                newActiveSection = section.id;
            }
        })

        setActiveSection(newActiveSection)
    }

    useEffect(() => {
        sections.current = document.querySelectorAll('[data-section]');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const activeStyle = {
        // fontWeight: 'bold',
        color: 'black',
        textDecoration: 'underline',
        fontSize: '16px',
        padding: "18px 35px"
    }





    return (<>
        <Layout>

            <div style={{ width: "100%", marginBottom: "-5%" }}>
                <div className="headermainDiv_Tips">
                    <div className="headerMianInner_Tips">
                        <h2>How to raise funds for NGO?</h2>
                        <h1>There are several ways NGOs can fundraise for <br></br> social causes.</h1>
                        <h3>Make use of all the secret fundraising weapons Ketto has to offer!</h3>
                    </div>
                </div>
                <div className="secondMainDiv_tips">
                    <div style={{ paddingLeft: "10%" }}>
                        <div data-section className="secondTips1point" id="section1">
                            <h1>MARATHONS & OTHER RACING EVENTS</h1>
                            <div>
                                <p>An organized event like a marathon is a great way to crowdfund (fundraise) a social cause.</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                            <div>
                                <p style={{ fontWeight: "600" }}>Advantages</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                        </div>
                        <div data-section className="secondTips1point" id="section2">
                            <h1>CORPORATE & STUDENT VOLUNTEERS</h1>
                            <div>
                                <p>Give student volunteers the opportunity to nurture their social conscience and corporate volunteers the chance to earn CSR.</p>

                                <ul>
                                    <li>Check with your volunteers and see if they would like to crowdfund (fundraise online) for your NGO</li>
                                    <li>We help them start an online fundraiser for your NGO</li>
                                    <li>They reach out to their network via social media to raise funds</li>
                                    <li>They help you spread awareness and raise funds</li>
                                </ul>
                            </div>
                            <div>
                                <p style={{ fontWeight: "600" }}>Advantages</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                        </div>
                        <div data-section className="secondTips1point" id="section3">
                            <h1>FESTIVALS/AWARENESS DAYS</h1>
                            <div>
                                <p>Make supporters aware of the many occasions they can use as a theme to start a crowdfunding fundraiser around.
                                    For instance, festivals like Diwali and Christmas, and awareness days like World Hunger Day, World AIDS Day etc.
                                    (Check our blog for updates)</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                            <div>
                                <p style={{ fontWeight: "600" }}>Advantages</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                        </div>
                        <div data-section className="secondTips1point" id="section4">
                            <h1>E-COMMERCE</h1>
                            <div>
                                <p>Sell merchandise, mementos, artworks etc. to raise funds for your NGO</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                            <div>
                                <p style={{ fontWeight: "600" }}>Advantages</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                        </div>
                        <div data-section className="secondTips1point" id="section5">
                            <h1>EMPLOYEE ENGAGEMENT SOLUTIONS</h1>
                            <div>
                                <p>Offer corporate sponsors a recruitment solution and a way to build employee engagement, with CSR.</p>
                                <p><strong style={{ fontWeight: "700" }}>Employee Engagement -</strong> Corporates can get their employees to compete (individually, intra-department-wise or as teams, inter-department-wise) by running crowdfunding (online fundraising) fundraisers. Whoever raises the most for your NGO, wins.</p>
                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                            <div>
                                <p style={{ fontWeight: "600" }}>Advantages</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                        </div>
                        <div data-section className="secondTips1point" id="section6">
                            <h1>CORPORATE HIRING SOLUTIONS</h1>
                            <div>
                                <p>Offer corporate sponsors a recruitment solution and a way to build employee engagement, with CSR.Offer corporate sponsors a recruitment solution and a way to build employee engagement, with CSR.</p>
                                <p><strong style={{ fontWeight: "700" }}>Hiring Solution -</strong> Corporates can use crowdfunding as a recruitment task for prospective employees. Those that raise the most within the given deadline move to the next round.</p>
                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                            <div>
                                <p style={{ fontWeight: "600" }}>Advantages</p>

                                <ul>
                                    <li>Check which of your supporters participate in marathons/racing events.</li>
                                    <li>Reach out and give them the opportunity to crowdfund (fundraise online) their walk/run for your NGO.</li>
                                    <li>We help them start an online fundraiser for your NGO.</li>
                                    <li>They reach out to their network via social media to raise funds.</li>
                                    <li>They help you spread awareness and raise funds.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="thirdMainDiv">
                    <a href={pagePaths.signup}><h1>Be a social hero. Start a fundraiser now &nbsp;&nbsp; <BsArrowRightCircleFill /> </h1></a>

                </div>
            </div>
        </Layout>
    </>)
}