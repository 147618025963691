import React, { useEffect, useState } from "react";
import '../styles/signUp.css'
import EmailLogo from '../svg/email.svg'
import SignUpUserLogo from '../svg/signUser.svg'
import MobileLogo from '../svg/signupMobile.svg'
import SignUpEye from '../svg/eye-off.svg'
import FacebookLogo from '../svg/faceBook_Logo.svg';
import GoogleSignInLogo from '../svg/google_logo.svg';
import SignUpLeftShaide from '../svg/signupLeftShade.svg'
import CompanyLogo from "../component/companyLogo/companyLogo";
import { pagePaths } from "../utils/constants";
import RectMobile from '../images/RectanglMobile.png'
import axios from "axios";


export const SignUp = () => {
    const [data, setData] = useState({
        fullname: "",
        email: "",
        username: "",
        mobile: "",
        password: "",
    })

    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const [country, setCountry] = useState();
    const [countryCode, setCountryCode] = useState();
    const [state, setState] = useState();
    const [city, setCity] = useState()
    const [pincode, setPincode] = useState()

    const geolocationAPI = navigator.geolocation;

    const getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            setCountry(data.country_name)
            setCountryCode(data.country_code)
            setState(data.region)
            setCity(data.city);
            setPincode(data.postal)
        }).catch((error) => {
        });
    };
    useEffect(() => {
        getGeoInfo()
    }, [])




    const getUserCoordinates = () => {
        if (!geolocationAPI) {

        } else {
            geolocationAPI.getCurrentPosition((position) => {
                const { coords } = position;
                setLat(coords.latitude);
                setLong(coords.longitude);
            }, (error) => {

            })
        }
    }

    useEffect(() => {
        getUserCoordinates();
    }, [])


    const [fname, lname] = data.fullname.split(' ');

    const handleChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        const userData = {
            fname: fname,
            lname: lname,
            email: data.email,
            username: data.username,
            mobile: data.mobile,
            password: data.password,
            countrycode: countryCode,
            country: country,
            state: state,
            city: city,
            pincode: pincode
        };
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup`, userData).then((response) => {
        }
        );
    };


    return (
        <>
            <div className="mainDiv">
                <div className="uppperMianDiv">
                    <img src={SignUpLeftShaide} className="SignUpLeftShaide" />
                    <div>
                        <div className="headerMainDivSignUp" style={{ display: "flex", textAlign: "center" }}>
                            <div className="logoDiv">
                                <a href={pagePaths.root}> <CompanyLogo /></a>
                            </div>
                            <div className="signupHeader">
                                <h1>Signup</h1>
                                <div style={{ textAlign: "center", marginTop: "5%" }}>
                                    <h4>Create account to start Masoom Trust</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details_signUp">
                        <div className="signupEmail">
                            <div className="signEmailLogo">
                                <img src={EmailLogo} />
                            </div>
                            <div className="signEmailInput">
                                <input
                                    type="text"
                                    name="fullname"
                                    value={data.fullname}
                                    placeholder="Full Name"
                                    className="input"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="signupEmail">
                            <div className="signEmailLogo">
                                <img src={EmailLogo} />
                            </div>
                            <div className="signEmailInput">
                                <input
                                    type="email"
                                    name="email"
                                    value={data.email}
                                    placeholder="Email"
                                    className="input"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="signupEmail">
                            <div className="signEmailLogo">
                                <img src={SignUpUserLogo} />
                            </div>
                            <div className="signEmailInput">
                                <input type="text"
                                    name="username"
                                    value={data.username}
                                    placeholder="User Name"
                                    className="input"
                                    onChange={handleChange} />
                            </div>
                        </div>
                        <div className="signupEmail">
                            <div className="signEmailLogo">
                                <img src={MobileLogo} />
                            </div>
                            <div className="signEmailInput">
                                <input type="number"
                                    name="mobile"
                                    value={data.number}
                                    placeholder="Mobile Number"
                                    className="input"
                                    onChange={handleChange} />
                            </div>
                        </div>
                        <div className="signupEmail">
                            <div className="signEmailLogo">
                                <img src={SignUpEye} />
                            </div>
                            <div className="signEmailInput">
                                <input type="password"
                                    name="password"
                                    value={data.password}
                                    placeholder="Password"
                                    className="input"
                                    onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="signUpBtn">
                        <div className="btnSignup">
                            <button type="submit" onClick={(e) => handleSubmit(e)}>Signup</button>
                        </div>
                        <div className="facebook-googleBtn">
                            <div className="leftCircleShade"></div>
                            <button>
                                Login with <img src={FacebookLogo} />
                            </button>
                            <button>
                                Login with <img src={GoogleSignInLogo} />
                            </button>
                        </div>
                        <div className="leftdownCircleShade"></div>
                    </div>
                </div>
                <div className="lowerMainDiv">
                    <img src={RectMobile} className="ractImgMobile" />
                </div>
            </div>
        </>
    )
}