import React, { useState } from "react";
import "../styles/forgetPassword.css"
import CompanyLogo from "../component/companyLogo/companyLogo";
import EmailLogo from '../svg/email.svg'
import ForgetNumber from '../svg/signupMobile.svg'
import ForgotRightImage from '../svg/forgotRightImage.svg'
import { pagePaths } from "../utils/constants";
import axios from "axios";

export const ForgetPassword = () => {
    
    const [forgetdata,setForgetData] = useState({
        email:"",
        mobile:"",
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        const userData = {
            email: forgetdata.email,
            password: forgetdata.password,
        };
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/forgot-password`, userData).then((response) => {
        }
        );
    };


    
    const handleChange = (e) => {
        const value = e.target.value;
        setForgetData({
            ...forgetdata,
            [e.target.name]: value
        });
    };
    return (
        <>
            <div>
                <div>
                    <CompanyLogo />
                </div>
                <div className="forgotPasswordTopShade"></div>
                <div className="mainDiv_forgetPassword">
                    <div className="mainLeftDiv_forgetPassword">
                        <div className="upperDiv_forgotPassword">
                            <h1>Forgot Password</h1>
                            <h5>Enter the given below details to rest your password</h5>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div className="forgetEmail">
                                <div className="signEmailLogo_forgotPassword">
                                    <img src={EmailLogo} />
                                </div>
                                <div className="forgotEmailInput">
                                    <input
                                       type="email"
                                       name="email"
                                       value={forgetdata.email}
                                       placeholder="Enter Email"
                                       className="input"
                                       onChange={handleChange} 
                                    />
                                </div>
                            </div>
                            <div className="forgotPasswordOR">
                                <h2>OR</h2>
                            </div>
                            <div className="forgetEmail">
                                <div className="signEmailLogo_forgotPassword">
                                    <img src={ForgetNumber} />
                                </div>
                                <div className="forgotEmailInput">
                                    <input
                                       type="number"
                                       name="mobile"
                                       value={forgetdata.password}
                                       placeholder="Enter Mobile Number"
                                       className="input"
                                       onChange={handleChange} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="btnReset">
                            <a onClick={handleSubmit} href={pagePaths.forgotPasswordOTP}>Reset Password</a>
                        </div>
                        <div className="forgotPasswordbottomShade" ></div>
                    </div>
                    <div className="mainrightDiv_forgetPassword">
                        <img src={ForgotRightImage} />
                    </div>
                    <div className="forgotPasswordRightShade"></div>
                </div>
            </div>
        </>
    )
}