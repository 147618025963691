import React from "react";
import "../styles/ourGalliersDetailsPage.css";
import Layout from "../component/Layout";
import GalleryImg1 from "../images/galleryImg1.png";


const galleryImage = [GalleryImg1, GalleryImg1, GalleryImg1, GalleryImg1, GalleryImg1];

export const GalleriesDetailPage = () => {
    return (
        <Layout>
            <div className="mainDiv_GalliersDetailsPage">
                <div className="innerMainDiv_GalliersDetailsPage">
                    <div className="header_GalliersDetailsPage">
                        <h2>
                            Al Nasar Hospital Dialysis
                            <div className="underLine_ActivitiesHealthHealth"></div>
                        </h2>
                    </div>
                    <div className="displayGalleryImg__GalliersDetailsPage">
                        {
                            galleryImage?.map((img, id) => {
                                return (<>
                                    <div className="displayGalleryImgInner__GalliersDetailsPage">
                                        <img src={img} />
                                    </div>
                                </>)
                            })
                        }
                    </div>

                </div>
            </div>
        </Layout>
    )
}