import React, { useEffect, useState } from "react";
import '../styles/browser_fundraiser.css'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import ToolBar from '../component/toolbar/toolbar'
import PrevBtn from '../images/prev.png'
import NextBtn from '../images/nxt.png'
import Rectangle_41 from '../images/Rectangle_41.png'
import Footer from "./footer";
import { MdOutlineArrowDropDownCircle } from 'react-icons/md'
import axios from "axios";
import { ProgressBar, ProgressBar1 } from "./progressbar";
import { useSearchParams } from "react-router-dom";

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

const slidePerRow = 1;

export const CategoriesForDropDown = () => {
    const [categories, setCategories] = useState(false)
    const [dataCampaing, setDataCampaing] = useState([]);
    const [dataTrending, setDataTrending] = useState([]);
    const [dataCategories, setDataCategories] = useState([]);
    const [next, setNext] = useState(slidePerRow)
    const [activeLoad, setActiveLoad] = useState(false);

    const [searchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const id = params.id;

    console.log("inside browserfundraiser", id, dataCategories)

    const fetchData_Campaing = async () => {
        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/campaigns`;

            const res = await axios.get(url)
            const { data } = res;
            setDataCampaing(data)
        } catch (error) {

        }
    }


    const fetchData_Categories = async () => {
        //api call
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/site/category/All`;

            const res = await axios.get(url)
            const { data } = res;
            setDataCategories(data)
        } catch (error) {

        }
    }



    const handleMoreSlide = () => {
        setNext(next + slidePerRow)
        setActiveLoad(true)
    }
    const handleLessSlide = () => {
        setNext(slidePerRow)
        setActiveLoad(false)

    }



    useEffect(() => {
        fetchData_Campaing()
        fetchData_Categories()
    }, []);
    return (
        <>
            <div>
                <div>
                    <ToolBar />
                </div>
                <div>
                    <div className="upperMianDiv_Cate">
                        <div className="categoriesUpperDiv">
                            {
                                dataCategories?.map((item)=>{
                                    console.log("checking item name",item.name)
                                    if(item.id ==id){
                                        return <h1 className="categoriesHeader" >{item.name}</h1>
                                    }
                                })
                            }
                        </div>
                    </div>


                    {/* *****<<<<<<<<<next slide is started *******>>>>>>>>>> */}


                    {/* *****<<<<<<<<<next slide is started *******>>>>>>>>>> */}




                    <div className="Categories_Div_lower">
                        <AliceCarousel infinite autoPlay={true} autoPlayInterval={7000} responsive={responsive} disableDotsControls renderPrevButton={() => {
                            return <img src={PrevBtn} className="Categories_prevSvg" />
                        }} renderNextButton={() => {
                            return <img src={NextBtn} className="Categories_nxtSvg" />
                        }} >

                            {
                                dataCampaing?.map((item, i) => {

                                    const progressBarPercent = (item.raisedAmount / item.goalAmount) * 100;
                                    const progressBarMaxWidth = () => {
                                        if (progressBarPercent > 100) {
                                            return 100
                                        } else {
                                            return progressBarPercent
                                        }
                                    }
                                    console.log("checking ids", item.id, id)
                                    if (item.id == id) {
                                        return (<>
                                            <div className="Categories_sliderImgMainDiv" >
                                                <div className="Categories_sliderImgMain">
                                                    <a key={i} href={`aboutuspage?id=${item.id}`}><img src={item.image1} className="Categories_sliderimg" /></a>
                                                    <div className="Categories_belowSliderImg">
                                                        <div className="catgriesSliderDataleft">
                                                            <h4 className="catgriesSliderDataleftUpper">{item.title}</h4>
                                                            <button>
                                                                Donate Now
                                                            </button>
                                                        </div>
                                                        <div className="catgriesSliderDataRight" >
                                                            <h2 className="categriesSliderRightPanelupper">₹{item.raisedAmount}<br /> </h2>
                                                            <h3 className="categriesSliderRightPanellower">raised out of ₹{item.goalAmount}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ProgressBar1 progressBarPercent={progressBarMaxWidth()} />
                                            </div>
                                        </>)
                                    }
                                })
                            }
                        </AliceCarousel>
                    </div>





                    {/* *****<<<<<<<<<next slide is started *******>>>>>>>>>> */}



                    <div className="savingMore">
                        <div className="savingMoreInner">
                            <div className="savingMore1">
                                <img src={Rectangle_41} />
                            </div>
                            <div className="savingMore2">
                                <div className="savingMore2Inner">
                                    <h1 className="savingh1_1">What’s Better Than Saving One Child?</h1>
                                    <h1 className="savingh1_2">Saving 12 More!</h1>
                                </div>
                                <div className="savingBtnDiv">
                                    <button className="savingBtn">
                                        Start giving Monthly
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="savingMore3">
                        </div>
                    </div>
                    <div className="conatin-talkUsMainDiv" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div className="conatin-talkUs">
                            <h2>Confused about how to start a Fundraiser?</h2>
                            <button>Talk us</button>
                        </div>
                    </div>


                    {/* *****<<<<<<<<<next slide is started *******>>>>>>>>>> */}


                    <div className="Categories_Div_lower">
                        <AliceCarousel infinite autoPlay={true} autoPlayInterval={7000} responsive={responsive} disableDotsControls renderPrevButton={() => {
                            return <img src={PrevBtn} className="Categories_prevSvg" />
                        }} renderNextButton={() => {
                            return <img src={NextBtn} className="Categories_nxtSvg" />
                        }} >
                            {
                                dataCampaing?.slice(0, slidePerRow)?.map((item, i) => {
                                    const progressBarPercent = (item.raisedAmount / item.goalAmount) * 100;
                                    const progressBarMaxWidth = () => {
                                        if (progressBarPercent > 100) {
                                            return 100
                                        } else {
                                            return progressBarPercent
                                        }
                                    }
                                    if (item.id == id) {
                                        return (<>
                                            <div className="Categories_sliderImgMainDiv" >
                                                <div className="Categories_sliderImgMain">
                                                    <a key={i} href={`aboutuspage?id=${item.id}`}><img src={item.image1} className="Categories_sliderimg" /></a>
                                                    <div className="Categories_belowSliderImg">
                                                        <div className="catgriesSliderDataleft">
                                                            <h4 className="catgriesSliderDataleftUpper">{item.title}</h4>
                                                            <button>
                                                                Donate Now
                                                            </button>
                                                        </div>
                                                        <div className="catgriesSliderDataRight" >
                                                            <h2 className="categriesSliderRightPanelupper">₹{item.raisedAmount}<br /> </h2>
                                                            <h3 className="categriesSliderRightPanellower">raised out of ₹{item.goalAmount}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ProgressBar1 progressBarPercent={progressBarMaxWidth()} />
                                            </div>
                                        </>)
                                    }
                                })
                            }
                        </AliceCarousel>
                    </div>


                    {
                        activeLoad
                            ?
                            <div className="Categories_Div_lower">
                                <AliceCarousel infinite autoPlay={true} autoPlayInterval={7000} responsive={responsive} disableDotsControls renderPrevButton={() => {
                                    return <img src={PrevBtn} className="Categories_prevSvg" />
                                }} renderNextButton={() => {
                                    return <img src={NextBtn} className="Categories_nxtSvg" />
                                }} >
                                    {
                                        dataCampaing?.slice(slidePerRow, next)?.map((item, i) => {
                                            const progressBarPercent = (item.raisedAmount / item.goalAmount) * 100;
                                            const progressBarMaxWidth = () => {
                                                if (progressBarPercent > 100) {
                                                    return 100
                                                } else {
                                                    return progressBarPercent
                                                }
                                            }
                                            return (<>
                                                <div className="Categories_sliderImgMainDiv" >
                                                    <div className="Categories_sliderImgMain">
                                                        <a key={i} href={`aboutuspage?id=${item.id}`}><img src={item.image1} className="Categories_sliderimg" /></a>
                                                        <div className="Categories_belowSliderImg">
                                                            <div className="catgriesSliderDataleft">
                                                                <h4 className="catgriesSliderDataleftUpper">{item.title}</h4>
                                                                {/* <h5 style={{ color: "white" }}>{item.description}</h5> */}
                                                                <button>
                                                                    Donate Now
                                                                </button>
                                                            </div>
                                                            <div className="catgriesSliderDataRight" >
                                                                <h2 className="categriesSliderRightPanelupper">₹{item.raisedAmount}<br /> </h2>
                                                                <h3 className="categriesSliderRightPanellower">raised out of ₹{item.goalAmount}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ProgressBar1 progressBarPercent={progressBarMaxWidth()} />
                                                </div>
                                            </>)
                                        })
                                    }
                                </AliceCarousel>
                            </div>
                            :
                            null
                    }
                    < div className="loadMore_CategoriesBTN">
                        {next < dataCampaing?.length ? (
                            <button
                                onClick={handleMoreSlide}
                            >
                                Load More
                            </button>
                        ) : <button
                            onClick={handleLessSlide}
                        >
                            {activeLoad ? "Load Less" : "Load More"}
                        </button>}
                    </div>
                </div>
                <Footer />
            </div >
        </>
    )
}
