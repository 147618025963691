import React from "react";
import '../../styles/managefund.css'
import WidthDraw from "../../svg/withdraw.svg"
import Location from "../../svg/locationsvg.svg"
import Dashboard from "../../svg/dashboard.svg"
import Care from "../../svg/donation.svg"
import Frame1 from "../../images/Frame1.png"
import User from "../../images/user.png"
import Trophy from "../../images/trophy.png"
import Ellips_img from "../../images/Ellips_img.svg"
import Ellips_3 from "../../svg/Ellipse__3.svg"
import Payment from "../../images/payment.png"
import Group from "../../images/group.png"
import Email from "../../images/email.png"
import SmartPhone from "../../images/smartphone.png"
import Pen from "../../images/pen.png"
import Rectangle_33 from '../../images/Rectangle_33.png'
import Small_ellipls from '../../svg/small_ellipls.svg'

const widthOutPut = window.screen.width;
const headerMainFontSize = (5 / 100) * widthOutPut;


const ManageFund = () => {
    return (
        <>
            <div className="main_Div_Manage">

                <div className="header_Manage">
                    <h1 style={{
                        fontSize:`${headerMainFontSize}px`
                    }}>Manage your fundraisers on the go</h1>
                </div>
                <div className="manage_Manage">
                    <div className="contain_Manage">
                        <div className="contain_Manage_img">
                            <img src={WidthDraw} />
                        </div>
                        <div className="containH4Div_Manage">
                            <h4>Withdraw your funds faster</h4>
                        </div>
                        <div className="manageFundUpperBtn">
                            <button>Learn More</button>
                        </div>
                    </div>
                    <div className="contain_Manage">
                        <div className="contain_Manage_img">
                            <img src={Location} />
                        </div>
                        <div className="containH4Div_Manage">
                            <h4>Start Fundraiser with in seconds</h4>
                        </div>
                        <div className="manageFundUpperBtn">
                            <button>Team up now</button>
                        </div>
                    </div>
                    <div className="contain_Manage">
                        <div className="contain_Manage_img">
                            <img src={Care} />
                        </div>
                        <div className="containH4Div_Manage">
                            <h4>Keep track of all your Donations Recived</h4>
                        </div>
                        <div className="manageFundUpperBtn">
                            <button>Learn More</button>
                        </div>
                    </div>
                    <div className="contain_Manage">
                        <div className="contain_Manage_img">
                            <img src={Dashboard} />
                        </div>
                        <div className="containH4Div_Manage">
                            <h4>Access a personalized dashboard</h4>
                        </div>
                        <div className="manageFundUpperBtn">
                            <button>Explore</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ width: "100%" }}>
                        <div className="card_1_Manage">
                            <div style={{ marginTop: "5%" }}>
                                <img className="tajImg" src={Frame1} style={{ width: "8rem" }} />
                            </div>
                            <div className="rightMinDivManage" style={{ marginTop: "5%", width: "40%" }}>
                                <div className="iconMainDiv_Manage">
                                    <div className="iconDiv_Manage">
                                        <svg width="12" height="14" stroke="#fff" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.66 4C7.1 2.82 5.9 2 4.5 2H0V0H12V2H8.74C9.22 2.58 9.58 3.26 9.79 4H12V6H9.98C9.73 8.8 7.37 11 4.5 11H3.77L10.5 18H7.73L1 11V9H4.5C6.26 9 7.72 7.7 7.96 6H0V4H7.66Z" fill="black" />
                                        </svg>
                                    </div>
                                    <div className="iconDiv_Manage">
                                        <img src={User} />
                                    </div>
                                    <div className="iconDiv_Manage">
                                        <img src={Trophy} />
                                    </div>
                                </div>
                                <div className="name_Btn_Manage">
                                    <h3>
                                        Indian Teamin
                                    </h3>
                                    <button>Join Now</button>
                                </div>
                                <div className="lineDemo">
                                    <div className="lineDemo1_Manage"></div>
                                    <div className="lineDemo2_Manage"></div>
                                    <div className="lineDemo3_Manage"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card2MianDiv_Manage">
                        <div className="card2Header_Manage">
                            <h2>Supporters Profile</h2>
                        </div>
                        <div className="card2InnerMainDiv_Manage">
                            <div style={{ width: "50%" }}>
                                <div className="card2Header_Manage">
                                </div>
                                <div className="card2leftDiv_Manage">
                                    <div className="card2leftImg_Manage">
                                        <img src={Ellips_img} />
                                    </div>
                                    <div className="card2leftdetails_Manage">
                                        <div className="card2leftdetails1_Manage" style={{ marginTop: "20%" }}>
                                            <img src={Group} />
                                            <h4>Team Organizer</h4>
                                        </div>
                                        <div className="card2leftdetails1_Manage" style={{ marginTop: "10%" }}>
                                            <img src={Payment} />
                                            <h4>Recurring Doner</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                <div>
                                    <div className="card2LowerMainDiv_Manage">
                                        <h3>₹1,00,000</h3>
                                        <h4>raised to date</h4>
                                    </div>
                                </div>
                                <div className="lastIconDiv_Manage">
                                    <div className="lastIcon_Manage">
                                        <img src={Email} />
                                        <img src={SmartPhone} />
                                        <img src={Pen} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card3mainDiv_Manage">
                    <div className="card3mainDiv_header_Manage">
                        <h2>SUPPORTERS</h2>
                        <h2>ROLE</h2>
                    </div>
                    <div className="card3mainDiv_contain_Manage">
                        <div className="card3mainDiv_Card3_Manage">
                            <img src={Ellips_3} />
                            <h2>Sumit Kalsi</h2>
                            <h2>Organizer</h2>
                        </div>
                        <div className="card3mainDiv_Card3_Manage">
                            <img src={Ellips_3} />
                            <h2>Neelam Chopra</h2>
                            <h2>Doner</h2>
                        </div>
                    </div>
                </div>
                <div className="card4mainDiv_Manage">
                    <div className="card4InnerDiv_Manage">
                        <div>
                            <img className="card4mainDivImage_Manage" src={Rectangle_33} />
                        </div>
                        <div className="card4RightDiv_Manage">
                            <div>
                                <div className="card4RightDiv_ManageTittle">
                                    <h3>My Grace Fundraiser</h3>
                                </div>
                                <div className="card4RightContianDiv_Manage">
                                    <img src={Small_ellipls} style={{ width: "1rem" }} />
                                    <h5>By Neelam Chopra</h5>
                                </div>
                            </div>
                            <div className="card4RightDiv_Manage_price">
                                ₹2.50,000 / ₹4,00,000
                            </div>
                            <div className="donate_Manage">
                                <h5>Donate</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="designShade_Manage"></div>
            </div>
        </>
    )
}
export default ManageFund;