import React from "react";
import "../styles/medicalSupport.css"
import Layout from "../component/Layout";
import MedicalAnimatedImg from "../images/medicalImg.jpg"

export const MedicalSupport = () => {
    return (<>
        <Layout>
            <div className="medicalSupportMainDiv">
                <div>
                    <div className="medicalSupportImage">
                        <div className="medicalHeaderDiv">
                            <h2>Medical Financing</h2>
                            <h2>Made Easy</h2>
                            <h4>Zero <strong>Interest</strong> Zero <strong>Repayment</strong> Zero <strong>worries</strong></h4>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </>)
}